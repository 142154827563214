import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/HFC logo-2-White.png";

function Footer() {
  return (
    <footer className="bg-dark text-white text-center text-lg-start">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <img src={logo} alt="Company Logo" className="footer-logo" />
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="footer-heading">Sponsors</h5>
            <ul className="list-unstyled mb-0">
              <li className="footer-item">
                <a href="https://web.facebook.com/sandsfabricators/?_rdc=1&_rdr#" className="footer-link">
                  S&S Fabricators
                </a>
              </li>
              <li className="footer-item">
                <a href="https://web.facebook.com/holydumplinglk/?_rdc=1&_rdr#" className="footer-link">
                 Holy Dumpling
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="footer-heading">Associations</h5>
            <ul className="list-unstyled mb-0">
              <li className="footer-item">
                <a href="https://www.afso.info/" className="footer-link">
                  AFSO
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="footer-heading">Clubs</h5>
            <ul className="list-unstyled mb-0">
              <li className="footer-item">
                <a href="https://web.facebook.com/share/g/1XUcrwWcrG/" className="footer-link">
                  Ultimax Combat Club
                </a>
              </li>
              <li className="footer-item">
                <a href="https://web.facebook.com/p/Natural-MMA-BJJ-100063805461856/?_rdc=1&_rdr#" className="footer-link">
                  Natural Grappling Academy
                </a>
              </li>
              <li className="footer-item">
                <a href="https://web.facebook.com/TeamFusionLK/?_rdc=1&_rdr" className="footer-link">
                  Fusion Martial Arts
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center p-3 footer-bottom">
        © 2024 Highland Fighting Championship, Inc. All rights reserved.
      </div>
      <style>
        {`
          @keyframes spinY {
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(360deg);
            }
          }

          @keyframes glow {
            0% {
              text-shadow: 0 0 5px #ff6347, 0 0 10px #ff6347, 0 0 15px #ff6347;
            }
            50% {
              text-shadow: 0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347;
            }
            100% {
              text-shadow: 0 0 5px #ff6347, 0 0 10px #ff6347, 0 0 15px #ff6347;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes slideIn {
            from {
              width: 0;
            }
            to {
              width: 50px;
            }
          }

          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
            }
          }

          .footer-logo {
            width: 150px;
            animation: spinY 4s linear infinite, pulse 2s ease-in-out infinite;
            filter: drop-shadow(0 0 10px #ff6347);
          }

          .footer-heading {
            font-size: 1.25rem;
            position: relative;
            margin-bottom: 20px;
            animation: fadeIn 2s ease-in-out;
          }

          .footer-heading::before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 50px;
            height: 2px;
            background-color: #ff6347;
            animation: slideIn 1s ease-in-out;
          }

          .footer-item {
            margin-bottom: 10px;
            animation: fadeInUp 1.5s ease-in-out;
          }

          .footer-link {
            color: white;
            text-decoration: none;
            transition: color 0.3s, transform 0.3s, text-shadow 0.3s;
          }

          .footer-link:hover {
            color: #ff6347;
            transform: translateX(5px);
            text-decoration: none;
            animation: glow 0.6s infinite;
          }

          .footer-bottom {
            background-color: #222;
            padding: 15px 0;
            font-size: 0.9rem;
            color: #aaa;
            border-top: 1px solid #333;
            animation: fadeIn 3s ease-in-out;
          }
        `}
      </style>
    </footer>
  );
}

export default Footer;
