// import React from "react";
// ;

// function  {
//     const location = useLocation();
//     const { employeeEmail } = location.state;
//     return <div>{employeeEmail}</div>;
// }

// export default AddWorkoutsForTrainer;
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Container, Row, Col, Form, ListGroup, Button } from "react-bootstrap"; // Import Bootstrap components
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import WorkoutDetails from "../components/WorkoutDetails";
import WorkoutForm from "../components/WorkoutForm";
import WorkoutFormForTrainer from "../components/WorkoutFormForTrainer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  workoutItem: {
    marginBottom: 5,
  },
});

const WorkoutPDF = ({ workouts }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Workout List</Text>
        {workouts.map((workout) => (
          <Text key={workout._id} style={styles.workoutItem}>
            {workout.title}
          </Text>
        ))}
      </View>
    </Page>
  </Document>
);

const AddWorkoutsForTrainer = () => {
  const { workouts, dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState(""); // State to store search term
  const [filteredWorkouts, setFilteredWorkouts] = useState([]); // State to store filtered workouts
  const location = useLocation();
  const { employeeEmail } = location.state;
  //     return ;

  useEffect(() => {
    if (workouts) {
      // Filter workouts based on search term
      const filtered = workouts.filter((workout) =>
        workout.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredWorkouts(filtered);
    }
  }, [searchTerm, workouts]);

  useEffect(() => {
    const fetchWorkouts = async () => {
      console.log();
      if (user) {
        const response = await fetch(`/api/workouts/trainer/${employeeEmail}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        console.log("frontend responce", response);

        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "SET_WORKOUTS", payload: json });
        }
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [dispatch, user]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="bg66">
      <Container
        className="mt-5"
        style={{ maxWidth: "900px", marginLeft: "200px", marginTop: "0px" }}
      >
        <Row>
          <Col md={{ span: 6, offset: 0 }}>
            <Form.Control
              type="text"
              placeholder="Search Workouts"
              value={searchTerm}
              onChange={handleSearchChange}
              className="mb-3"
              size="lg" // Set the size to large
            />
            <div className="d-flex flex-column align-items-center">
              <h4 className="workout-form-div-title">Add a New Workout For</h4>
              <h5 style={{ color: "grey" }}> {employeeEmail}</h5>
            </div>
            <ListGroup>
              {filteredWorkouts.map((workout) => (
                <WorkoutDetails key={workout._id} workout={workout} />
              ))}
            </ListGroup>
            {/* <Button variant="primary" className="mt-3">
              <PDFDownloadLink
                document={<WorkoutPDF workouts={filteredWorkouts} />}
                fileName="workout-list.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Generating PDF..." : "Download PDF"
                }
              </PDFDownloadLink>
            </Button> */}
          </Col>
          <Col md={{ span: 4, offset: 1 }}>
            <WorkoutFormForTrainer added_to_email={employeeEmail} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddWorkoutsForTrainer;
