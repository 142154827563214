import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import img from "../assets/img033.jpg";
import CreateIcon from '@mui/icons-material/Create';

export default function SendEmail() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [showEmailFields, setShowEmailFields] = useState(false);
  const navigate = useNavigate();

  const clean = () => {
    setSubject("")
    setBody("")
  }


  

  // Fetch employees data
  useEffect(() => {
    axios
      .get("https://www.hfc.lk/newfighter/displayAllEmp")
      .then((res) => {
        setEmployees(res.data);
        setFilteredEmployees(res.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  // Filter employees based on search term and pagination
  useEffect(() => {
    const filtered = employees.filter((employee) =>
      Object.values(employee).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setFilteredEmployees(filtered.slice(startIndex, endIndex));
  }, [employees, searchTerm, pageSize, currentPage]);

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  const totalPages = Math.ceil(filteredEmployees.length / pageSize);

  const handleCheckboxChange = useCallback((employeeId) => {
    setSelectedEmployees((prev) =>
      prev.includes(employeeId)
        ? prev.filter((id) => id !== employeeId)
        : [...prev, employeeId]
    );
  }, []);

  // const handleCheckboxChange = (event, id) => {
  //   const updatedSelectedEmployees = event.target.checked
  //     ? [...selectedEmployees, id]
  //     : selectedEmployees.filter(employeeId => employeeId !== id);
    
  //   setSelectedEmployees(updatedSelectedEmployees);
  // };

  const handleSendEmail = useCallback(() => {
    if (selectedEmployees.length === 0) {
      alert("Please select employees to send the email.");
      return;
    }

    if (window.confirm("Are you sure you want to email the selected fighters?")) {
      const selectedEmails = selectedEmployees
        .map((id) => employees.find((employee) => employee._id === id)?.Email)
        .filter(Boolean)
        .join(",");

      if (!subject || !body) {
        alert("Subject and Body cannot be empty!");
        return;
      }

      axios
        .post("https://www.hfc.lk/email/sendEmail", {
          to: selectedEmails,
          subject,
          body,
        })
        .then(() => {
          alert("Email has been sent successfully!");
          setShowEmailFields(false); // Hide the email form
          setSelectedEmployees([]); // Clear selected employees
          setSubject(""); // Clear the subject field
          setBody(""); // Clear the body field
        })
        .catch(() => {
          alert("Error in sending email!");
        });
    }
  }, [selectedEmployees, employees, subject, body]);

  const handleWriteEmailClick = useCallback(() => setShowEmailFields(true), []);

  const handleDeleteClick = useCallback(async (eid) => {
    if (window.confirm("Are you sure you want to proceed with removing this fighter?")) {
      try {
        await axios.delete(`https://www.hfc.lk/newfighter/delete/${eid}`);
        const res = await axios.get("https://www.hfc.lk/newfighter/displayAllEmp");
        setEmployees(res.data);
        setFilteredEmployees(res.data);
      } catch (error) {
        console.error("Error deleting employee:", error.message);
      }
    }
  }, []);

  return (
    <div
      className="vh-100 d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container my-5 bg-light rounded shadow-lg p-4"   style={{
          opacity: "0.95",
          width: "90%",
          height: "75%",
          overflow: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}>
        <center style={{ padding: "7px" }}>
          <h3>Highland Fighting Championship</h3>
          <h5>Registered Fighters</h5>
        </center>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <input
            type="text"
            className="form-control w-60"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
          {!showEmailFields && (
            <div
              className={`btn ${showEmailFields ? "" : ""}`}
              onClick={showEmailFields ? handleSendEmail : handleWriteEmailClick}
              disabled={!showEmailFields && selectedEmployees.length === 0}
            >
             <CreateIcon/>
            </div>
          )}
        </div>

        {showEmailFields && (
          <div className="card mb-2 parent-div d-flex">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10 mb-1">
                  <input
                    id="subject"
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="col-md-2 mb-3 ml-auto">
                <span
          className="material-symbols-outlined"
          onClick={() => {
            setShowEmailFields(false);
          }}
        >
          cancel
        </span>

        <span
          className="material-symbols-outlined ml-1"
          onClick={() => {
            handleSendEmail();
          }}
        >
          send
        </span>
        <span
          className="material-symbols-outlined ml-1"
          onClick={() => {
            clean();
          }}
        >
          cleaning
        </span>
                  {/* <button className="btn btn-secondary me-2" onClick={() => setShowEmailFields(false)}>Cancel</button> */}
                  {/* <button className="btn btn-primary" onClick={handleSendEmail}>Send</button> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <textarea
                    id="body"
                    className="form-control"
                    value={body}
                    placeholder="Body"
                    onChange={(e) => setBody(e.target.value)}
                    rows="2"
                    
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th >Email</th>
                <th>Age</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Club</th>
                <th>Contact Number</th>
                <th>Coach</th>
                <th>YOE</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee._id}>
                  <td>{employee.FirstName}</td>
                  <td >{employee.Email}</td>
                  <td>{employee.Age}</td>
                  <td>{employee.Height}</td>
                  <td>{employee.Weight}</td>
                  <td>{employee.Club}</td>
                  <td>{employee.Discipline}</td>
                  <td>{employee.Coach}</td>
                  <td>{employee.YOE}</td>
                  <td>
                    {/* <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteClick(employee._id)}
                    >
                      <i className="bi bi-trash"></i>
                    </button> */}
                     <span
          className="material-symbols-outlined ml-1"
          onClick={() => {
            handleDeleteClick(employee._id);
          }}
        >
          delete
        </span>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(employee._id)}
                      checked={selectedEmployees.includes(employee._id)}
                    />
                    {/* <input
  type="checkbox"
  onChange={(event) => handleCheckboxChange(event, employee._id)}
  checked={selectedEmployees.includes(employee._id)}
  style={{
    appearance: 'none',
    width: '20px',
    height: '20px',
    border: '2px solid black',
    backgroundColor: selectedEmployees.includes(employee._id) ? 'black' : 'white',
  }}
/> */}

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <buttonbtn
            className=""
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            <i className="bi bi-arrow-left"></i>
          </buttonbtn>
          <span className="mx-2">{`${currentPage} of ${totalPages}`}</span>
          <buttonbtn
            className=""
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            <i className="bi bi-arrow-right"></i>
          </buttonbtn>
        </div>
      </div>
    </div>
  );
}
