import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img033.jpg";

const AboutUsParagraph = () => {
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    minHeight: "100vh",
    color: "#f5f5f5",
    backgroundColor: "#2c3e50",
    position: "relative",
    overflow: "hidden",
  };

  const textContainerStyle = {
    flex: 1,
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    animation: "fadeIn 1s ease-in-out",
    zIndex: 1,
  };

  const headingStyle = {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "2.5rem",
    marginBottom: "2rem",
    color: "#ecf0f1",
  };

  const paragraphStyle = {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "1.1rem",
    lineHeight: "1.8",
    marginBottom: "1.5rem",
    color: "#bdc3c7",
  };

  const imageContainerStyle = {
    flex: 1,
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: hover ? "grayscale(20%)" : "grayscale(80%)",
    transition: "filter 0.5s ease",
    display: "block",
  };

  return (
    <div style={containerStyle}>
      <div style={textContainerStyle}>
        <h2 style={headingStyle}>About Highland Fighting Championship</h2>
        <p style={paragraphStyle}>
          The Highland Fighting Championship is a private entity dedicated to
          providing a platform for young Sri Lankan MMA talents. Established in
          December 2023, the company held its debut tournament on December 16,
          2013. As the first-ever Sri Lankan outdoor MMA championship, the event
          takes place in the cultural city of Kandy, a location rich in history
          and the former home of the last Sinhalese monarchy. The championship
          adheres to the A.F.S.O MMA International Organization rulebook and
          takes all necessary measures to ensure the safety and well-being of
          the fighters.
        </p>
        <p style={paragraphStyle}>
          Kandy has a storied history of martial prowess, with traditional
          fighting arts like Angampora, an ancient martial art that combines
          combat techniques, self-defense, and sport, originating in Sri Lanka.
          The city has been a center for military training and cultural
          preservation, with historical warriors playing a crucial role in
          defending the last Sinhalese kingdom against colonial powers. This
          rich heritage of combat and resilience adds a significant historical
          context to the modern MMA events hosted by the Highland Fighting
          Championship.
        </p>
      </div>
      {!isMobile && (
        <div
          style={imageContainerStyle}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        ></div>
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="display: flex"] {
            flex-direction: column;
          }
          div[style*="background-image"] {
            display: none;
          }
          div[style*="padding: 3rem"] {
            text-align: center;
            padding: 1rem;
          }
        }
      `}</style>
    </div>
  );
};

export default AboutUsParagraph;
