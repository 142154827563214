// rthis file is to create the workout form

// we are using useState
// we have to import useState from react

import React, { useState } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";

export function fillForm(title, reps, load) {
  setTitle(title);
  setLoad(load);
  setReps(reps);
}

const WorkoutFormForTrainer = ({ added_to_email }) => {
  const { dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();

  // creating states

  // we are going to crete a form with three fields
  // when user types into this fields ststes are going to be updated
  // initial value for this is an empty string
  const [title, setTitle] = useState("");
  const [load, setLoad] = useState("");
  const [reps, setReps] = useState("");
  // if responce is not ok
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  // creating handle submit function
  // function is async beacouse we are reaching API
  //  we are taking event object

  const handleDemoSubmit = async (e) => {
    handleDemoSubmit2(e, "Dumbbell Flyes", 10, 10);
    handleDemoSubmit2(e, "Leg press", 30, 10);
    handleDemoSubmit2(e, "Decline sit-ups", 10, 10);
    handleDemoSubmit2(e, "Bench press", 50, 10);
    handleDemoSubmit2(e, "Deadlift", 100, 10);
    // handleDemoSubmit2(e,'Cable crossover',10,10)
    // handleDemoSubmit2(e,'Squat',10,10)
    // handleDemoSubmit2(e,'Bent-over row',10,10)
    // handleDemoSubmit2(e,'Push-up',10,10)
  };

  const handleDemoSubmit2 = async (e, t, l, r) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }
    var title = t;
    var load = l;
    var reps = r;
    var added_by_email = user.email;
    var added_by_role = user.role ? user.role : "user";
    const workout = {
      title,
      load,
      reps,
      added_by_email,
      added_by_role,
      added_to_email,
    };

    console.log(
      "front end form ",
      title,
      load,
      reps,
      added_by_email,
      added_by_role
    );

    try {
      const response = await fetch("/api/workouts", {
        method: "POST",
        body: JSON.stringify(workout),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
        setEmptyFields(json.emptyFields);
      }

      if (response.ok) {
        dispatch({ type: "CREATE_WORKOUT", payload: json });
      }
    } catch (error) {
      console.error("Error adding workout:", error);
    }
  };

  const handleSubmit = async (e) => {
    // preventing default action
    // nomally default action is reload the page
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    // create workout object
    var added_by_email = user.email;
    var added_by_role = user.role ? user.role : "user";
    const workout = {
      title,
      load,
      reps,
      added_by_email,
      added_by_role,
      added_to_email,
    };

    // useing fletch API to send a post request
    try {
      //  post request is going to '/api/workouts'
      // second argument is an object with some options
      const response = await fetch("/api/workouts", {
        // specify the method
        method: "POST",
        // we want body property to send workout object
        // we have to change our object into json
        // this changes our object into json string
        body: JSON.stringify(workout),
        // headers property
        headers: {
          //  content type is json
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();

      //  when we send a post request we are handling it on controller
      //  we are firing createWorkout function
      //  if it is success we are returning json(workout)
      //  if ot is not a success we are sending json({error:error.message})

      //  responce is now stored in responce constant

      // if it is not ok
      // if we get an error back we are going to update the state to error
      if (!response.ok) {
        // updating the state
        // in json we have an error property json({error:error.message})
        setError(json.error);
        setEmptyFields(json.emptyFields);
      }

      if (response.ok) {
        // reset the form
        setTitle("");
        setLoad("");
        setReps("");
        setError(null);
        setEmptyFields([]);
        console.log("new workout added", json);
        dispatch({ type: "CREATE_WORKOUT", payload: json });
        alert("Succesfully Added new workout\n\n " + json.title);
      }
    } catch (error) {
      console.error("Error adding workout:", error);
    }
  };

  // inside the function we are returning some template

  return (
    <div
      className="workout-form-div"
      style={{ width: "400px", marginTop: "80px" }}
    >
      {/* creating the form */}
      {/* on submit we are firing handleSubmit function */}
      <form className="create" onSubmit={handleSubmit}>
        <label>Exercise Title:</label>
        <input
          type="text"
          // onChange -> when types in to the field
          // onChange we are going to fire a function
          // we are going to take  the event object
          // when user type into this field we are going to call
          // setaTitle function
          onChange={(e) => setTitle(e.target.value)}
          // two way data binding
          // if user reset the form that change is going to be reflected
          value={title}
          // className={emptyFields.includes("title") ? "error" : ""}
        />

        <label>Load (in kg):</label>
        <input
          type="number"
          // onChange -> when types in to the field
          onChange={(e) => setLoad(e.target.value)}
          value={load}
          // className={emptyFields.includes("load") ? "error" : ""}
        />

        <label>Reps:</label>
        <input
          type="number"
          onChange={(e) => setReps(e.target.value)}
          value={reps}
          // className={emptyFields.includes("reps") ? "error" : ""}
        />

        {/* submit button */}
        <button className="btn btn-primary" type="submit">
          Add Workout
        </button>
      </form>
      {/* <form className="demo-add-workout" onSubmit={handleDemoSubmit}>
        <button type="submit" className="workout-form-demo-button">
          Demo
        </button>
      </form> */}

      {/* if thire is an error we output it */}
      {error && (
        <div className="error" style={{ color: "white" }}>
          {error}
        </div>
      )}
      <div className="workout-form-actions">
        {/* <button className='delete-all-workouts-button' onClick={deleteAllWorkouts}>
       Delete
       </button> */}
      </div>
    </div>
  );
};

// export this to use in homepage
export default WorkoutFormForTrainer;

// we are tracking what user type into the form
// we are storing that information in that states
