import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function AllUsers() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    function getEmployees() {
      axios
        .get("http://localhost:4000/employee/displayAllUsers")
        .then((res) => {
          setEmployees(res.data);
          setFilteredEmployees(res.data);
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    getEmployees();
  }, []);

  useEffect(() => {
    const filtered = employees.filter((employee) =>
      employee.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmployees(filtered);
  }, [searchTerm, employees]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset current page when changing page size
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    doc.setProperties({
      title: "User List",
      subject: "List of All Users",
      author: "",
      keywords: "user, list",
    });

    const headers = ["First Email"];

    const data = filteredEmployees.map((employee) => [
      employee.Email,
    
    ]);

    // Set proper alignment for the topic
    doc.setFontSize(18);
    doc.text("User Details", 105, 20, { align: "center" });

    // Reset font size for the table
    doc.setFontSize(12);

    const columnStyles = {
      0: { cellWidth: 25, halign: "left" },
     
    };

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      columnStyles,
    });

    return doc;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const downloadPdf = () => {
    const doc = generatePdf();
    const url = URL.createObjectURL(doc.output("blob"));
    window.open(url, "_blank");
  };

  const handleDeleteClick = async (eid) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this employee?"
    );

    if (isConfirmed) {
      try {
        await axios.delete(`http://localhost:4000/employee/delete/user/${eid}`);
        axios
          .get("http://localhost:4000/employee/displayAllUsers")
          .then((res) => {
            setEmployees(res.data);
            setFilteredEmployees(res.data);
          })
          .catch((err) => {
            alert(err.message);
          });
      } catch (error) {
        console.error("Error deleting employee:", error.message);
      }
    }
  };

  const totalPages = Math.ceil(filteredEmployees.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedEmployees = filteredEmployees.slice(startIndex, endIndex);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center mb-4">All Users</h1>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              placeholder="Search by Email"
              className="form-control w-50"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button onClick={downloadPdf} className="btn btn-primary ms-3">
              <i className="bi bi-download"></i> Download
            </button>
          </div>

          <table className="table table-striped">
            <thead className="table-dark">
              <tr>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedEmployees.map((employee) => (
                <tr key={employee._id}>
                  <td>{employee.email}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm me-2"
                      onClick={() => {
                        navigate("/addworkoutsfortrainer", {
                          state: { employeeEmail: employee.email },
                        });
                      }}
                    >
                      <i className="bi bi-person-walking"></i> Add Workout
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteClick(employee._id)}
                    >
                      <i className="bi bi-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              Showing {Math.min((currentPage - 1) * pageSize + 1, filteredEmployees.length)} to{" "}
              {Math.min(currentPage * pageSize, filteredEmployees.length)} of {filteredEmployees.length} entries
            </div>
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
