import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const cartstate = useSelector((state) => state.cartReducer);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = () => {
    logout();
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark bg-dark ${
        isSticky ? "fixed-top" : ""
      }`}
    >
      <div className="container">
        <Link
          className="navbar-brand mr-auto"
          to="/"
          style={{ paddingLeft: "5px" }}
        >
          HIGHLAND FIGHTING CHAMPIONSHIP
        </Link>

        <div
          className={`collapse navbar-collapse ${isDropdownOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ml-auto">
            {user ? (
              <>
                <li className="nav-item" style={{ paddingLeft: "5px" }}>
                  <span className="navbar-text mr-3">
                    {user.isUser ? "User" : ""}
                    {user.role ? user.role : ""}
                  </span>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item" style={{ paddingLeft: "5px" }}>
                  <Link className="nav-link" to="/login">
                    LOGIN
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/signup">
                    SIGN UP
                  </Link>
                </li> */}
              </>
            )}

            <li className="nav-item dropdown" style={{ paddingLeft: "5px" }}>
              <div className="nav-link" type="button" onClick={toggleDropdown}>
                MENU
              </div>
              <div className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                {user && user.role === "SYSTEM ADMIN" && (
                  <>
                    {/* <Link className="dropdown-item" to="/viewUsers">
                        Users
                      </Link>
                      <Link className="dropdown-item" to="/add">
                        Provide Access
                      </Link>
                      <Link className="dropdown-item" to="/viewEmployees">
                        View Employees
                      </Link>
                      <Link className="dropdown-item" to="/editemp">
                        Edit Employee
                      </Link>
                    <Link className="dropdown-item" to="/aboutUs">
                      Members
                    </Link>
                     */}
                    <Link className="dropdown-item" to="/aboutUsParagraph">
                      About Us
                    </Link>
                    <Link className="dropdown-item" to="/allfighters">
                      Fighters
                    </Link>
                    <Link className="dropdown-item" to="/applications">
                      Applications
                    </Link>
                    <Link className="dropdown-item" to="/viewmessages">
                      Messages
                    </Link>
                   
                   
                  </>
                )}
                {!user && (
                  <>
                  
                   
                    <Link className="dropdown-item" to="/aboutUsParagraph">
                      About Us
                    </Link>
                   
                   
                  </>
                )}
              </div>
            </li>

            {user && (
              <li className="nav-item" style={{ paddingLeft: "5px" }}>
                <button className="nav-link" onClick={handleClick}>
                  LOG OUT
                </button>
              </li>
            )}
          </ul>
        </div>

        {isScreenSmall && (
          <div
            className="three-dots"
            style={{
              position: "absolute",
              right: "10px",
              outline: "none",
              color: "white",
              paddingBottom: "10px",
              paddingLeft: "5px",
            }}
            data-toggle="collapse"
            data-target="#navbarNav"
          >
            <i className="bi bi-three-dots-vertical"></i>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
