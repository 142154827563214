import React from 'react'

export default function FlipFusionSingleCard( {card, handleChoice, flipped, disabled} ) {

    const handleClick = () => {
      if(!disabled){
        handleChoice(card)
      }
       
    }
  return (
    <div className='flipfusioncard' >
    <div className={flipped ? 'flipped' : ''}>
      <img className='flipfusioncardfront' src={card.src} alt='card front'/>
      <img className='flipfusioncardback' 
           src='/img/card7.jpg' 
           onClick={handleClick} 
           alt='card back'/>
    </div>
  </div>  
  )
}

