import React, { useState, useEffect } from "react";

const RelaxingGame = () => {
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(30); // 30 seconds game
  const [gameOver, setGameOver] = useState(false);

  // Generate random position for bubbles
  const getRandomPosition = () => {
    const min = 10;
    const max = 90;
    const left = Math.floor(Math.random() * (max - min + 1)) + min;
    const top = Math.floor(Math.random() * (max - min + 1)) + min;
    return { left: `${left}%`, top: `${top}%` };
  };

  // Function to handle bubble click
  const handleBubbleClick = (value) => {
    if (!gameOver) {
      setScore(score + value);
    }
  };

  // Function to handle game over
  const handleGameOver = () => {
    setGameOver(true);
  };

  // Function to start the game
  const startGame = () => {
    setScore(0);
    setTime(30);
    setGameOver(false);
  };

  // Function to restart the game
  const restartGame = () => {
    startGame();
  };

  // Countdown timer effect
  useEffect(() => {
    if (time > 0 && !gameOver) {
      const timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (time === 0) {
      handleGameOver();
    }
  }, [time, gameOver]);

  // Function to generate random bubble size
  const getRandomSize = () => {
    return Math.floor(Math.random() * (100 - 30 + 1)) + 30; // Random size between 30 and 100
  };

  // Function to assign points based on bubble size
  const assignPoints = (size) => {
    // if (size >= 80) {
    //   return 5;
    // } else if (size >= 60) {
    //   return 3;
    // } else {
    //   return 1;
    // }
    return 1;
  };

  // Function to generate realistic bubble colors
  const getRealisticColor = () => {
    const hue = Math.floor(Math.random() * 360); // Random hue value
    const saturation = Math.floor(Math.random() * 21) + 80; // Random saturation value between 80 and 100
    const lightness = Math.floor(Math.random() * 21) + 70; // Random lightness value between 70 and 90
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <div className="bg41">
      <div className="relaxing-game" style={{ color: "white" }}>
        <h1>Bubble Pop</h1>
        <div className="game-controls" style={{ color: "white" }}>
          {!gameOver ? (
            <button className="btn btn-outline-light" onClick={startGame}>
              Start Game
            </button>
          ) : (
            <p>Refresh the page to play again!</p>
          )}
          <p>Click on bubbles to pop them!</p>
        </div>
        {!gameOver ? (
          <>
            <div className="scoreboard" style={{ color: "white" }}>
              <h2>Score: {score}</h2>
              <h2>Time: {time}s</h2>
            </div>
            <div className="bubble-container">
              {/* Generate bubbles */}
              {[...Array(10)].map((_, index) => {
                const size = getRandomSize();
                const points = assignPoints(size);
                const bubbleColor = getRealisticColor(); // Get realistic bubble color
                const randomDelay = Math.random() * 3; // Random delay for animation
                return (
                  <div
                    key={index}
                    className="bubble"
                    onClick={() => handleBubbleClick(points)}
                    style={{
                      ...getRandomPosition(),
                      width: `${size}px`,
                      height: `${size}px`,
                      backgroundColor: bubbleColor,
                      animationDelay: `-${randomDelay}s`, // Apply animation delay
                    }}
                  ></div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="game-over">
            <h2>Game Over! Your score: {score}</h2>
            <button onClick={restartGame} className="btn btn-outline-light">
              Restart
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RelaxingGame;

// CSS
const styles = `
.relaxing-game {
  text-align: center;
  font-family: Arial, sans-serif;
}

.scoreboard {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.bubble-container {
  position: relative;
  width: 80vw;
  height: 80vh;
}

.bubble {
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  animation: bubble 5s linear infinite; // Smooth motion animation
}

@keyframes bubble {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.game-controls {
  margin-top: 20px;
}

.game-controls button {
  
}

.game-controls button:hover {
 
}

.game-over {
  margin-top: 20px;
}

.game-over h2 {
  font-size: 2rem;
}

.game-over button {
 
}

.game-over button:hover {
  
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
