import React, { useEffect, useState } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import WorkoutForm from "../components/WorkoutForm";
import WorkoutDetails from "../components/WorkoutDetails";
import img from "../assets/img033.jpg";

const Home = () => {
  const { workouts, dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const workoutsPerPage = 3;

  useEffect(() => {
    if (workouts) {
      setFilteredWorkouts(
        workouts.filter(
          (workout) =>
            workout.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            workout.fighter1.toLowerCase().includes(searchTerm.toLowerCase()) ||
            workout.fighter2.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, workouts]);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch("/api/workouts");
      if (response.ok) {
        const json = await response.json();
        dispatch({ type: "SET_WORKOUTS", payload: json });
      }
    };
    fetchWorkouts();
  }, [dispatch, user]);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setProperties({
      title: "Workout",
      subject: "List of exercises",
      author: "Highland Fighting Championship",
      keywords: "workout, list",
    });

    const headers = ["Event", "Fight", "Winner", "Method", "Round", "Time"];
    const data = filteredWorkouts.map((workout) => [
      workout.title,
      `${workout.fighter1} vs ${workout.fighter2}`,
      workout.winner,
      workout.method,
      workout.round,
      workout.time,
    ]);

    doc.setFillColor(255, 255, 255);
    doc.rect(0, 0, 210, 297, "F");

    doc.setFontSize(22);
    doc.setFont("arial", "");
    doc.setTextColor(40, 40, 40);
    doc.text("Highland Fighting Championship", 105, 20, { align: "center" });

    doc.setFontSize(18);
    doc.setFont("helvetica");
    doc.setTextColor(100, 100, 100);
    doc.text("Results", 105, 30, { align: "center" });

    doc.setDrawColor(22, 160, 133);
    doc.setLineWidth(0.4);
    doc.line(10, 35, 200, 35);

    const columnStyles = {
      0: { cellWidth: 30, halign: "left" },
      1: { cellWidth: 50, halign: "left" },
      2: { cellWidth: 25, halign: "center" },
      3: { cellWidth: 25, halign: "center" },
      4: { cellWidth: 25, halign: "center" },
      5: { cellWidth: 25, halign: "center" },
    };

    doc.setFontSize(12);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 40,
      styles: { fontSize: 10, cellPadding: 4, valign: "middle" },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: 255,
        fontStyle: "bold",
      },
      bodyStyles: { fillColor: [245, 245, 245], textColor: 50 },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      columnStyles,
      margin: { top: 10 },
      tableWidth: "auto",
      theme: "grid",
      drawRow: (row, data) => {
        if (row.index === data.table.body.length - 1) {
          row.cells.forEach((cell) => {
            cell.styles.fillColor = [220, 220, 220];
            cell.styles.fontStyle = "bold";
          });
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(150, 150, 150);
      doc.text(`Page ${i} of ${pageCount}`, 105, 290, { align: "center" });

      doc.setFontSize(8);
      doc.text("Generated by Highland Fighting Championship", 105, 295, {
        align: "center",
      });
    }

    return doc;
  };

  const downloadPdf = () => {
    const doc = generatePdf();
    const url = URL.createObjectURL(doc.output("blob"));
    window.open(url, "_blank");
  };

  const toggleFormVisibility = () => setFormVisible(!formVisible);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const paginatedWorkouts = filteredWorkouts.slice(
    (currentPage - 1) * workoutsPerPage,
    currentPage * workoutsPerPage
  );

  const totalPages = Math.ceil(filteredWorkouts.length / workoutsPerPage);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <center>
        <div style={{ minHeight: "100vh" }}>
          <div
            style={{
              opacity: "1",
              padding: "20px 0",
              textAlign: "center",
              color: "#fff",
            }}
          ></div>

          <Container style={{ paddingTop: "30px", paddingBottom: "100px" }}>
            <Row className={formVisible ? "" : "justify-content-center"}>
              <Col md={formVisible ? 8 : 8}>
                <div className="d-flex" style={{ opacity: "0.85" }}>
                  <Form.Control
                    type="text"
                    placeholder="Search Workouts"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="me-2"
                    style={{ marginBottom: "4%" }}
                  />
                  {user && user.role === "SYSTEM ADMIN" && (
                    <Button
                      onClick={toggleFormVisibility}
                      className="btn btn-danger"
                    >
                      {formVisible ? "Hide" : "Add"}
                    </Button>
                  )}
                </div>

                <Row
                  xs={1}
                  md={2}
                  lg={3}
                  className="g-3"
                  style={{ paddingTop: "15px" }}
                >
                  {paginatedWorkouts.map((workout) => (
                    <Col key={workout._id}>
                      <WorkoutDetails key={workout._id} workout={workout} />
                    </Col>
                  ))}
                </Row>

                <Button
                  onClick={downloadPdf}
                  className="mt-3 btn btn-danger"
                  style={{ opacity: "0.85", float: "right" }}
                >
                  Download Results <i className="bi bi-download"></i>
                </Button>
                <div className="d-flex justify-content-center mt-3">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className="me-2"
                      style={{ opacity: "0.9" }}
                      variant={currentPage === index + 1 ? "danger" : "dark"}
                    >
                      {index + 1}
                    </Button>
                  ))}
                </div>
              </Col>
              {user && user.role === "SYSTEM ADMIN" && (
                <Col md={4} className={formVisible ? "" : "d-none"}>
                  <WorkoutForm />
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </center>
    </div>
  );
};

export default Home;
