// import React from 'react';
import logo from '../assets/HFC logo-2-White.png';

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useSignup";
import { useAuthContext } from "../hooks/useAuthContext";
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import img from '../assets/img015.jpg'

function NavigationBar() {
  return (
    <div style={{paddingTop:"0px"}}>
      <div style={styles.navbar}>
        <div style={styles.navSection}>
          <a href="/aboutUs" style={styles.navLink}>ABOUT US</a>
          <a href="#" style={styles.navLink}>RANKINGS</a>
          <a href="#" style={styles.navLink}>ATHLETES</a>
          <a href="#" style={styles.navLink}>NEWS</a>
        </div>
        <div style={styles.logo}>
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" style={styles.logoImage} />
          </Link>
        </div>
        <div style={styles.navSection}>
          <a href="#" style={styles.navLink}>GALLERY</a>
          <a href="#" style={styles.navLink}>CONTACT US</a>
          <a href="#" style={styles.navLink}>WATCH</a>
          <a href="#" style={styles.navLink}>LOGIN</a>
          {/* <a href="#" style={styles.searchIcon}>🔍</a> */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: ' 80px',
    paddingRight: ' 80px',
    backgroundColor: 'rgb(33, 37, 41)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    color:"white"
  },
  navSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  logoImage: {
    height: '60px', // Adjust the height to make the logo bigger
    animation: 'fadeIn 2s ease-in',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '18px',
   
    position: 'relative',
  },
  searchIcon: {
    fontSize: '20px',
    color: '#333',
    cursor: 'pointer',
  },
};

export default NavigationBar;
