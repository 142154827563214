import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img015.jpg";
import img2 from "../assets/img026.jpg";
import img3 from "../assets/img033.jpg";

function RegisterChoice() {
  const imageStyle = {
    height: "450px", // Set a fixed height
    objectFit: "cover", // Ensures the image covers the area without distortion
    width: "100%", // Ensures the image takes the full width of the card
    borderRadius: "10px",
  };

  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleMouseEnter1 = () => {
    setHover1(true);
  };

  const handleMouseLeave1 = () => {
    setHover1(false);
  };

  const overlayStyle = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: "10px",
    borderRadius: "25px",
    textAlign: "center",
  };

  const overlayStyle1 = {
    position: "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: "20px",
    borderRadius: "5px",
    textAlign: "center",
    marginTop: "50px",
    width: "300px",
  };

  // const cardTextStyle = {
  //   height: '80px',  // Ensure the text areas are the same height
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center'
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundImage: `url(${img3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mt-5" style={{ margin: "5%" }}>
        <div className="row justify-content-center">
          <div className="col-md-3 mb-4" style={{ minWidth: "300px", maxWidth:'300px' }}>
            <div
              className=" h-100"
              style={{
                borderRadius: "10px",
                transition: "transform 0.2s",
                transform: hover ? "scale(1.03)" : "scale(1)",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="position-relative">
                <img
                  src={img}
                  className="card-img-top"
                  style={imageStyle}
                  alt="Register as HFC fighter"
                />
                <div style={overlayStyle}>
                  <Link
                    to="/registernewfighter"
                    className="stretched-link text-decoration-none text-white"
                  >
                    <h4 className="card-title">REGISTER AS FIGHTER</h4>
                  </Link>
                </div>
              </div>
              <div style={overlayStyle1}>
                <p className="card-text">
                  Upon registering as an HFC fighter, you will be notified when
                  fight opportunities become available.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4" style={{ minWidth: "300px", maxWidth:'300px' }}>
            <div
              className=" h-100"
              style={{
                borderRadius: "10px",
                transition: "transform 0.2s",
                transform: hover1 ? "scale(1.03)" : "scale(1)",
              }}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <div className="position-relative">
                <img
                  src={img2}
                  className="card-img-top"
                  style={imageStyle}
                  alt="HFC 3 Registration"
                />
                <div style={overlayStyle}>
                  <Link
                    to="/registerfighter"
                    className="stretched-link text-decoration-none text-white"
                  >
                    <h4 className="card-title">SEASON 03 REGISTRATION</h4>
                  </Link>
                </div>
                <div style={overlayStyle1}>
                  <p className="card-text">
                    If you have been selected for HFC Season 03, please complete
                    this form.
                  </p>
                </div>
              </div>
              {/* <div className="card-body text-center" style={cardTextStyle}>
              
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterChoice;
