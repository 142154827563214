import React from "react";
import VidioBackground6 from "../videos/BackgroundVideo6";
import VidioBackground10 from "../videos/BackgroundVideo10";
import { Link } from "react-router-dom";

function GameCenter() {
  return (
    <div className="bg41">
      <div>
        {/* <Link to="/tictactoe" className="Topics" style={{ width: "75px" }}>
          <div className="p-home-div">
            <VidioBackground6 />
            <section>
              <h1 className="Topics">Tic Tac Toe</h1>

              <p className="p-home-topic-text">
                Play the classic game with a twist! Challenge your mind and have
                fun with our Tic Tac Toe game.
              </p>
            </section>
          </div>
        </Link> */}
        <Link
          to="/tictactoe"
          className="Topics"
          style={{ width: "75px", textDecoration: "none" }}
        >
          <div className="p-home-div">
            <VidioBackground10 />
            <section>
              <h1 className="Topics">TicTacToe</h1>

              <p className="p-home-topic-text">
                Meet the team! Get to know the dedicated individuals working
                behind the scenes for your well-being.
              </p>
            </section>
          </div>
        </Link>
        <Link
          to="/flipfusion"
          className="Topics"
          style={{ width: "75px", textDecoration: "none" }}
        >
          <div className="p-home-div">
            <VidioBackground10 />
            <section>
              <h1 className="Topics">Flip Fusion</h1>

              <p className="p-home-topic-text">
                Meet the team! Get to know the dedicated individuals working
                behind the scenes for your well-being.
              </p>
            </section>
          </div>
        </Link>
        <Link
          to="/relaxinggame"
          className="Topics"
          style={{
            width: "75px",
            marginBottom: "100px",
            textDecoration: "none",
          }}
        >
          <div className="p-home-div">
            <VidioBackground10 />
            <section>
              <h1 className="Topics">BubblePoP</h1>

              <p className="p-home-topic-text">
                Meet the team! Get to know the dedicated individuals working
                behind the scenes for your well-being.
              </p>
            </section>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default GameCenter;
