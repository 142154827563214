import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useAuthContext } from "../hooks/useAuthContext";
import img from '../assets/img013.jpg';

function AnnDetails(props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  // const [author, setAuthor] = useState(user.role);
  // const [authorname, setAuthorname] = useState(user.FirstName + " " + user.LastName);
  const [image, setImage] = useState("");
  const [isedit, setIsedit] = useState(false);

  const {
    data: blog,
    isLoading,
    error,
  } = useFetch("http://localhost:5000/blogs/" + id);

  const handleClick = () => {
    fetch("http://localhost:5000/blogs/" + blog.id, {
      method: "DELETE",
    })
      .then(() => {
        alert("Announcement deleted");
        navigate(-1);
      })
      .catch((error) => console.error("Error deleting announcement:", error));
  };

  const handleCancel = () => {
    navigate(-1);
  }

  const handleUpdate = () => {
    const updatedBlog = { title, body, author, authorname, image };
    fetch("http://localhost:5000/blogs/" + blog.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedBlog),
    })
      .then((response) => response.json())
      .then(() => {
        alert("Announcement updated");
        navigate(-1);
      })
      .catch((error) => console.error("Error updating announcement:", error));
  };

  useEffect(() => {
    if (blog) {
      setTitle(blog.title);
      setBody(blog.body);
      setImage(blog.image);
    }
  }, [blog]);

  return (
    <div
      style={{
        paddingTop: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${img})`,
        width: '100vw',
        height: '90vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflow: 'auto',
      }}
    >
      <center>
      <div className="d-flex justify-content-center align-items-center" style={{ padding: "30px", opacity:"0.9", maxWidth:"950px" }}>
        <div className="card w-50">
        
        
        
      { blog && ( <img 
  src={blog.image || 'https://via.placeholder.com/150'} 
  className="card-img-top img-fluid rounded"
  alt={blog.title} 
  style={{ 
    maxHeight: "300px",    // Limits the maximum height of the image
    objectFit: "cover",    // Ensures the image covers the entire container while maintaining aspect ratio
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Adds a subtle shadow for depth
    borderRadius: "8px",    // Adds a slight border radius for a softer look
    marginBottom:"15px"
  }} 
/>)}
          <div className="card-body">
            {isLoading && (
              <p className="text-center text-muted">Loading...</p>
            )}
            {error && (
              <p className="text-center text-danger">{error}</p>
            )}
            {blog && (
              <>


                {/* <img src={blog.image} alt="Announcement" className="img-fluid mb-3" /> */}


                
       



                <h2 className="card-title">{blog.title}</h2>
                <p className="text-muted">
                  Announcement by <br />
                  {blog.author} - {blog.authorname}
                </p>
                <p className="card-text">{blog.body}</p>
                {user && user.role === "System Admin" && (
                  <>
                    <button className="btn btn-danger me-2" onClick={handleClick}>
                      Delete
                    </button>

                    {!isedit && (
                      <button
                        className="btn btn-outline-danger me-2"
                        onClick={() => setIsedit(true)}
                      >
                        Update
                      </button>
                    )}
                    {isedit && (
                      <button className="btn btn-danger me-2" onClick={handleUpdate}>
                        Save Changes
                      </button>
                      
                    )}
                      {isedit && (
                      <button className="btn btn-outline-danger" onClick={handleCancel}>
                        Cancel
                      </button>
                      
                    )}
                  </>
                )}
              </>
            )}
            {user && user.role === "System Admin" && isedit && (
              <div className="mt-4">
                <input
                  type="text"
                  className="form-control mb-2"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
                <input
                  type="text"
                  className="form-control mb-2"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  placeholder="Image URL"
                />
                <textarea
                  className="form-control mb-2"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="Body"
                  rows="4"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      </center>
    </div>
  );
}

export default AnnDetails;
