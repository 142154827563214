import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import img from '../assets/img017.jpg';


function AnnCreate() {
  const { user } = useAuthContext();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [author, setAuthor] = useState(user.role);
  const [authorname, setAuthorname] = useState(user.FirstName + " " + user.LastName);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const blog = { title, body, author, authorname };
    setIsLoading(true);
    fetch("http://localhost:5000/blogs", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(blog),
    }).then(() => {
      alert("New announcement added");
      setIsLoading(false);
      navigate(-1);
    });
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDemo = () => {
    setTitle("Yoga Class Reminder");
    setBody(
      "Good morning! Just a reminder that our yoga class will begin in 10 minutes in the studio upstairs. All levels are welcome!"
    );
  };

  return (
    <div className="background-image" style={{
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
    <div className="d-flex align-items-center justify-content-center vh-100" style={{ opacity:"0.9" }}>
      <div className="container mt-5" style={{ paddingBottom: "80px", maxWidth: "800px" }}>
        <div className="row">
          <div className="col-md-6">
            <div className="card p-4">
              <h2 className="text-center mb-4">Create New Announcement</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">Announcement Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    required
                    value={title}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="body" className="form-label">Announcement</label>
                  <textarea
                    className="form-control"
                    id="body"
                    required
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="author" className="form-label">Author</label>
                  <input
                    className="form-control"
                    id="author"
                    value={author}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="authorname" className="form-label">Author Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="authorname"
                    value={authorname}
                    disabled
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={isLoading}
                  >
                    {isLoading ? "Adding Announcement..." : "Add Announcement"}
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-4 bg-dark text-white">
              <h2 className="text-center mb-4">Announcement Overview</h2>
              <div>
                <p><strong>Title:</strong> {title}</p>
                <p><strong>Author Position:</strong> {author}</p>
                <p><strong>Author Name:</strong> {authorname}</p>
                <p><strong>Announcement:</strong> {body}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default AnnCreate;
