
import React from 'react';

const VideoBackground10 = () => {
  return (
    <div id="p-video-container-div">
      <video autoPlay muted loop id="background-video">
        <source src="/videos/video10.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground10;