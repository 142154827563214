import React from 'react';

function TicTacToeStrike({ strikeClass }) {
  return (
    <div className={`strike ${strikeClass}`}>
      
    </div>
  );
}

export default TicTacToeStrike;
