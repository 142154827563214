import React, { useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { fillForm } from "../components/WorkoutFormForTrainer";
import UpdateWorkoutForm from "../components/UpdateWorkoutForm";

const WorkoutDetails = ({ workout }) => {
  const { dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/workouts/" + workout._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.ok) {
      dispatch({ type: "DELETE_WORKOUT", payload: json });
      alert("RESULT DELETED");
    }
  };

  const handleUpdate = async () => {
    if (!user) {
      return;
    }

    const response = await fetch("/api/workouts/" + workout._id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.ok) {
      console.log("correctly updated ", json);
      dispatch({ type: "UPDATE_WORKOUT", payload: json });
    }
    setIsEditing(false);
  };

  const styles = {
    container: {
      border: "2px solid #ccc",
      borderRadius: "8px",
      padding: "0px",
      marginBottom: "16px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      maxWidth: "300px",
      opacity: "0.85",
      maxHeight: "90%",
      overflow: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
    },
    detail: {
      marginBottom: "4px",
      // padding : "10px"
    },
    titleRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      textAlign: "center",
      flexGrow: 1,
      // paddingLeft : "90px"
    },
    icon: {
      fontSize: "20px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      {isEditing ? (
        <UpdateWorkoutForm
          workout={workout}
          onUpdate={handleUpdate}
          setIsEditing={setIsEditing}
        />
      ) : (
        <>
          <img
            src={workout.image}
            className="card-img-top img-fluid rounded"
            style={{
              height: "150px",
              width: "100%",
              objectFit: "cover",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px 8px 0 0",
            }}
          />
          <div style={{ padding: "15px" }}>
            <div style={styles.titleRow}>
              <h3 style={styles.title}>{workout.title}</h3>
            </div>
            <center>
              {" "}
              <h4>
                {workout.fighter1.split(" ")[0]} Vs{" "}
                {workout.fighter2.split(" ")[0]}
              </h4>
              <h5 style={styles.detail}>{workout.weightclass}</h5>
              <p style={{ paddingTop: "10px" }}>
                {workout.winner} won by {workout.method} at {workout.time} in
                round {workout.round}
              </p>
              {user && user.role === "SYSTEM ADMIN" && !isEditing && (
                <div style={{ padding: "10px" }}>
                  <span
                    className="material-symbols-outlined"
                    style={styles.icon}
                    onClick={() => setIsEditing(true)}
                  >
                    update
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={styles.icon}
                    onClick={handleClick}
                  >
                    delete
                  </span>
                </div>
              )}
            </center>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkoutDetails;
