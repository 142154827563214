import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";
import img from "../assets/img033.jpg";

const AboutUs = () => {
  const [lineHeight, setLineHeight] = useState(0);
  const [passedCircles, setPassedCircles] = useState([]);
  const timelineRef = useRef(null);
  const firstCircleRef = useRef(null);
  const lastCircleRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (
        timelineRef.current &&
        firstCircleRef.current &&
        lastCircleRef.current
      ) {
        const firstCirclePos =
          firstCircleRef.current.getBoundingClientRect().top + window.scrollY;
        const lastCirclePos =
          lastCircleRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;

        if (scrollTop + windowHeight >= firstCirclePos) {
          const newHeight = Math.min(
            lastCirclePos - firstCirclePos,
            scrollTop + windowHeight - firstCirclePos
          );
          setLineHeight(newHeight);
          updatePassedCircles(scrollTop + windowHeight);
        } else {
          setLineHeight(0);
          setPassedCircles([]);
        }
      }
    };

    const updatePassedCircles = (viewPortBottom) => {
      const circles = document.querySelectorAll(".timeline-circle");
      const passed = [];

      circles.forEach((circle, index) => {
        const circlePos = circle.getBoundingClientRect().top + window.scrollY;
        if (viewPortBottom >= circlePos) {
          passed.push(index);
        }
      });

      setPassedCircles(passed);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Set initial height

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const containerStyle = {
    color: "white",
    padding: "20px",
  };

  const titleStyle = {
    textAlign: "center",
    fontSize: "3rem",
    marginBottom: "30px",
  };

  const timelineStyle = {
    position: "relative",
    margin: "0 auto",
    padding: "40px 0",
    maxWidth: "400px",
  };

  const timelineItemStyle = {
    marginBottom: "40px",
    position: "relative",
    paddingLeft: "60px",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };

  const timelineIconStyle = {
    backgroundColor: "#d9534f",
    borderRadius: "50%",
    height: "20px",
    left: "0",
    position: "absolute",
    top: "0",
    width: "20px",
    zIndex: 2,
  };

  const timelineContentStyle = {
    backgroundColor: "#1c1c1c",
    borderRadius: "5px",
    padding: "20px",
    position: "relative",
    zIndex: 1,
    width: "100%",
  };

  const h3Style = {
    fontSize: "1.5rem",
    marginBottom: "10px",
  };

  const spanStyle = {
    color: "#d9534f",
  };

  const pStyle = {
    fontSize: "1rem",
    marginBottom: "5px",
  };

  const lineStyle = {
    content: '""',
    position: "absolute",
    width: "2px",
    backgroundColor: "#d9534f",
    top: "0",
    left: "9px",
    zIndex: 1,
    height: `${lineHeight}px`, // Set height dynamically
    boxShadow: "0 0 15px #d9534f", // Add glow effect
  };

  const outlineStyle = {
    position: "absolute",
    top: "-5px",
    left: "-5px",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "2px solid #d9534f",
    boxShadow: "0 0 10px #d9534f",
    zIndex: 1,
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const timelineVariants = {
    hidden: { height: 0 },
    visible: {
      height: lineHeight,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  const dotAnimationVariants = {
    hidden: { scale: 1 },
    visible: { scale: 1.2, transition: { yoyo: Infinity, duration: 0.5 } },
  };

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh", // Set minimum height to cover the viewport
      }}
    >
      <div style={containerStyle}>
        <center>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-center" style={{ color: "black" }}>
                  <h1>Highland Fighting Championship</h1>
                  <h2>Our-Journey</h2>
                </div>
                <div ref={timelineRef} style={timelineStyle}>
                  <motion.div
                    style={lineStyle}
                    initial="hidden"
                    animate="visible"
                    variants={timelineVariants}
                  ></motion.div>
                  {[...Array(5)].map((_, index) => (
                    <motion.div
                      ref={
                        index === 0
                          ? firstCircleRef
                          : index === 4
                          ? lastCircleRef
                          : null
                      }
                      className="timeline-circle"
                      style={timelineItemStyle}
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      key={index}
                    >
                      {passedCircles.includes(index) && (
                        <div style={outlineStyle} />
                      )}
                      <motion.div
                        style={timelineIconStyle}
                        layout
                        variants={dotAnimationVariants}
                      ></motion.div>
                      <div style={timelineContentStyle}>
                        <h3 style={h3Style}>
                          {index === 0
                            ? "Software Engineer"
                            : index === 1
                            ? "Intern"
                            : index === 2
                            ? "Software Developer"
                            : index === 3
                            ? "Software Developer Intern"
                            : "Teaching Assistant"}{" "}
                          <span style={spanStyle}>
                            @
                            {index === 0
                              ? "Google"
                              : index === 1
                              ? "Facebook"
                              : index === 2
                              ? "Amazon"
                              : index === 3
                              ? "Microsoft"
                              : "MIT"}
                          </span>
                        </h3>
                        <p style={pStyle}>
                          {index === 0
                            ? "2022-Present | Mountain View, CA"
                            : index === 1
                            ? "Summer 2021 | Menlo Park, CA"
                            : index === 2
                            ? "2020-2021 | Seattle, WA"
                            : index === 3
                            ? "Summer 2019 | Redmond, WA"
                            : "Fall 2018 | Cambridge, MA"}
                        </p>
                        <p style={pStyle}>
                          {index === 0
                            ? "Developed features for Google's search engine."
                            : index === 1
                            ? "Developed mobile app feature for Facebook."
                            : index === 2
                            ? "Worked on Amazon's mobile app."
                            : index === 3
                            ? "Developed new features for Windows."
                            : "Assisted in teaching computer programming."}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default AboutUs;
