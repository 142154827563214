import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img026.jpg";

export default function RegisterFighter() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [NIC, setNIC] = useState("");
  const [NickName, setNickName] = useState("");
  const [Email, setEmail] = useState("");
  const [Age, setAge] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [Club, setClub] = useState("");
  const [Discipline, setDisclipline] = useState("");
  const [Coach, setCoach] = useState("");
  const [School, setSchool] = useState("");
  const [City, setCity] = useState("");
  const [YOE, setYOE] = useState("");
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [err, setErr] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setError("");
    } else {
      setFile(null);
      setError("upload a PDF!");
    }
  };

  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "audio/mpeg") {
      setFile2(selectedFile);
      setError2("");
    } else {
      setFile2(null);
      setError2("upload a MP3!");
    }
  };

  const sendData = (e) => {
    e.preventDefault();

    if (file == null) {
      alert("You need to upload your Medical!");
      return;
    } else if (file2 == null) {
      alert("You need to upload your Ring Music!");
      return;
    }

    const formData = new FormData();
    formData.append("music", file2);
    formData.append("pdf", file);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("NIC", NIC);
    formData.append("NickName", NickName);
    formData.append("Email", Email);
    formData.append("Age", Age);
    formData.append("Height", Height);
    formData.append("Weight", Weight);
    formData.append("Club", Club);
    formData.append("Discipline", Discipline);
    formData.append("Coach", Coach);
    formData.append("School", School);
    formData.append("City", City);
    formData.append("YOE", YOE);

    axios
      .post("https://www.hfc.lk/fighter/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        const confirmed = window.confirm(
          "Please ensure all information is accurate before submitting. Changes cannot be made after registration, especially for ring music."
        );

        if (confirmed) {
          alert("Registration successful!");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status) {
          if (err.response.status === 325) {
            alert("Fighter Already Exists.");
          } else if (err.response.status === 324) {
            alert("Duplicate NIC. Please enter a unique NIC.");
          } else {
            alert("An error occurred while adding the fighter.");
          }
        } else {
          alert("An error occurred while adding the fighter.");
        }
      });
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setNIC("");
    setNickName("");
    setEmail("");
    setAge("");
    setHeight("");
    setWeight("");
    setClub("");
    setDisclipline("");
    setCoach("");
    setSchool("");
    setCity("");
    setYOE("");
    setFile(null);
    setFile2(null);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          width: "90%",
          backgroundColor: "white",
          borderRadius: "10px",
          maxWidth: "900px",
          maxHeight: "600px",
        }}
      >
        {!isMobile && (
          <div
            style={{
              flex: 1,
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          ></div>
        )}
        <div
          style={{
            flex: 1,

            padding: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgb(33, 37, 41)",
            color: "rgb(33, 37, 41)",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            ...(isMobile && {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }),

            overflow: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <style>
            {`
          .custom-placeholder::placeholder {
            color: white;
          }
        `}
          </style>
          <center
            style={{
              color: "white",

              ...(isMobile && {
                paddingTop: "420px",
              }),
            }}
          >
            <h3 style={{ marginTop: "10px" }}>Registeration </h3>
            <h4>Highland Fighting Championship</h4>
            <h5 style={{ paddingBottom: "8px" }}>Season 03</h5>
          </center>
          <form onSubmit={sendData} className="container">
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="First Name"
                  value={FirstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Last Name"
                  value={LastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="NIC"
                  value={NIC}
                  onChange={(e) => setNIC(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Nick Name"
                  value={NickName}
                  onChange={(e) => setNickName(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <input
                  type="email"
                  placeholder="Email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Age"
                  value={Age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Height"
                  value={Height}
                  onChange={(e) => setHeight(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Weight"
                  value={Weight}
                  onChange={(e) => setWeight(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Club"
                  value={Club}
                  onChange={(e) => setClub(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Discipline"
                  value={Discipline}
                  onChange={(e) => setDisclipline(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Coach"
                  value={Coach}
                  onChange={(e) => setCoach(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="School"
                  value={School}
                  onChange={(e) => setSchool(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="City"
                  value={City}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Years Of Experience"
                  value={YOE}
                  onChange={(e) => setYOE(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <label className="btn btn-outline-danger btn-block">
                  {file ? `Uploaded: ${file.name}` : "Medical"}
                  {error && <> {error}</>}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="application/pdf"
                    className="d-none"
                  />
                </label>
              </div>
              <div className="col-md-6 mb-2">
                <label className="btn btn-outline-danger btn-block">
                  {file2 ? `Uploaded: ${file2.name}` : "Ring Song"}
                  {error2 && <> {error2}</>}
                  <input
                    type="file"
                    onChange={handleFileChange2}
                    accept="audio/mpeg"
                    className="d-none"
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <button type="submit" className="btn btn-danger btn-block">
                  Submit
                </button>
              </div>
              <div className="col-md-6 mb-2">
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-outline-danger btn-block"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
