import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function AnnList(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedBlog, setSelectedBlog] = React.useState(null);

  const handleShowModal = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBlog(null);
  };

  const blogs = props.blogs;

  return (
    <div className="container mt-5">
      <div className="row">
        {blogs.map((blog) => (
          <div className="col-md-6 col-lg-4" key={blog.id}>
            <div 
              className="card mb-3 bg-dark text-white" 
              style={{ 
                transition: 'transform 0.2s, box-shadow 0.2s',
                cursor: 'pointer' 
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
              }}
            >
              {/* Card Image */}
              <div className="position-relative">
                <img 
                  src={blog.image || 'https://via.placeholder.com/150'} 
                  className="card-img-top" 
                  alt={blog.title} 
                  style={{ 
                    maxHeight: "170px", 
                    width: "100%", 
                    objectFit: "cover" 
                  }} 
                />
                <div className="position-absolute bottom-0 start-0 w-100 p-3">
                  <Link
                    to={`/annDetails/${blog.id}`}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <h5 className="card-title">{blog.title}</h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Quick View */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedBlog?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Author:</strong> {selectedBlog?.author}</p>
          <p><strong>Date:</strong> {new Date(selectedBlog?.date).toLocaleDateString()}</p>
          <p><strong>Category:</strong> {selectedBlog?.category}</p>
          <p>{selectedBlog?.content}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Link to={`/annDetails/${selectedBlog?.id}`} className="btn btn-primary">Read More</Link>
        </Modal.Footer>
      </Modal>

      {/* Pagination */}
      {/* <nav aria-label="Page navigation example" className="mt-4">
        <ul className="pagination justify-content-center">
          <li className="page-item"><Link className="page-link" to="#">Previous</Link></li>
          <li className="page-item"><Link className="page-link" to="#">1</Link></li>
          <li className="page-item"><Link className="page-link" to="#">2</Link></li>
          <li className="page-item"><Link className="page-link" to="#">3</Link></li>
          <li className="page-item"><Link className="page-link" to="#">Next</Link></li>
        </ul>
      </nav> */}
    </div>
  );
}

export default AnnList;
