import React, { useEffect, useState } from "react";
import FlipFusionSingleCard from "../components/FlipFusionSingleCard";

const cardImages = [
  { src: "/img/card2.jpg", matched: false },
  { src: "/img/card3.jpg", matched: false },
  { src: "/img/card1.jpg", matched: false },
  { src: "/img/card4.jpg", matched: false },
  { src: "/img/card5.jpg", matched: false },
  { src: "/img/card6.jpg", matched: false },
];

function FlipFusionHome() {
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const shuffleCard = () => {
    const shuffledCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

    setChoiceOne(null);
    setChoiceTwo(null);

    setCards(shuffledCards);
    setTurns(0);
  };

  console.log(cards, turns);

  const handleChoice = (card) => {
    console.log(card);
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);

      if (choiceOne.src === choiceTwo.src) {
        console.log("those cards match");
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        console.log("those cards dont match");

        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  console.log(cards);

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  useEffect(() => {
    shuffleCard();
  }, []);

  return (
    <div className="bg35">
      <div className="flipfucionhome">
        <h1 className="flipfusiontitle">Flip Fusion</h1>
        <button className="btn btn-outline-secondary" onClick={shuffleCard}>
          New Game
        </button>

        <div className="flipfusioncardgrid">
          {cards.map((card) => (
            <FlipFusionSingleCard
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              flipped={card === choiceOne || card === choiceTwo || card.matched}
              disabled={disabled}
            />
          ))}
        </div>

        <p className="flipfusionturn">Turns: {turns}</p>
      </div>
    </div>
  );
}

export default FlipFusionHome;
