import { useEffect, useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { Link } from "react-router-dom";
import img from "../assets/img033.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();
  const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
     };

     window.addEventListener("resize", handleResize);
     handleResize(); // Initial check

     return () => window.removeEventListener("resize", handleResize);
   }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
    // console.log(email, password)
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          backgroundColor: "white",
          borderRadius: "10px",
          maxWidth: "600px",
        }}
      >
          {!isMobile && (
          <div
            style={{
              flex: 1,
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          ></div>
        )}
        <div
          style={{
            flex: 1,
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgb(33, 37, 41)",
            color: "rgb(33, 37, 41)",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
             ...(isMobile && {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }),
          }}
        >
          <style>
            {`
          .custom-placeholder::placeholder {
            color: white;
          }
        `}
          </style>
          {/* <center style={{color:'white', paddingTop:"25px"}}>
<h3 >Registeration </h3>
          <h4>Highland Fighting Championship</h4>
          <h5>Season 03</h5>
         
          </center> */}
          <form
            onSubmit={handleSubmit}
            className="p-2 bg-dark text-white rounded"
            style={{ maxWidth: "300px", minHeight: "300px" }}
          >
            <h3 className="mb-3">LOGIN</h3>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                EMAIL:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                PASSWORD:
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
              />
            </div>

            <button
              disabled={isLoading}
              className="btn btn-danger w-30"
              style={{ marginTop: "15px" }}
            >
              LOGIN
            </button>

            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </form>
          <center>
            <div style={{ marginTop: "15px" }}>
              <a href="#!" className="text-light me-3">
                <i className="bi bi-tiktok"></i>
              </a>
              <a href="#!" className="text-light me-3">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#!" className="text-light me-3">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="#!" className="text-light">
                <i className="bi bi-youtube"></i>
              </a>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Login;
