import React, { useState } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const WorkoutForm = () => {
  const { dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();

  const [title, setTitle] = useState("");
  const [fighter1, setFighter1] = useState("");
  const [fighter2, setFighter2] = useState("");
  const [winner, setWinner] = useState("");
  const [weightclass, setWeightclass] = useState("");
  const [method, setMethod] = useState("");
  const [round, setRound] = useState("");
  const [time, setTime] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const[image,setImage]=useState("")

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    const workout = {
      title,
      fighter1,
      fighter2,
      winner,
      weightclass,
      method,
      round,
      time,
      image
    };

    try {
      const response = await fetch("/api/workouts", {
        method: "POST",
        body: JSON.stringify(workout),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
        setEmptyFields(json.emptyFields);
      }

      if (response.ok) {
        setTitle("");
        setFighter1("");
        setFighter2("");
        setWeightclass("");
        setWinner("");
        setTime("");
        setRound("");
        setMethod("");
        setImage("");
        console.log("new workout added", json);
        dispatch({ type: "CREATE_WORKOUT", payload: json });
        alert("NEW RESULT ADDED");
      }
    } catch (error) {
      console.error("Error adding workout:", error);
    }
  };

  return (
    <div style={{ width: "300px", margin: "0 auto", paddingTop: "10px",paddingBottom:"10px",paddingRight:"10px", border: "", borderRadius: "8px", boxShadow: "", paddingLeft:"10px",background:"white", opacity:"0.85", marginTop:"50px" }}>
     {/* <center style={{color:'black'}}> */}
{/* <h2 >ADD  </h2> */}
          {/* <h4 style={{paddingTop:"10px"}}>NEW FIGHT RESULT</h4> */}
          {/* <h4>Season 03</h4> */}
         
          {/* </center> */}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Event"
            style={{ width: "100%", padding: "6px", marginBottom: "5px 0", boxSizing: "border-box" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <div style={{ width: "48%" }}>
            <input
              type="text"
              onChange={(e) => setFighter1(e.target.value)}
              value={fighter1}
              placeholder="Fighter 1"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "48%" }}>
            <input
              type="text"
              onChange={(e) => setFighter2(e.target.value)}
              value={fighter2}
              placeholder="Fighter 2"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            onChange={(e) => setWeightclass(e.target.value)}
            value={weightclass}
            placeholder="Weight Class"
            style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            onChange={(e) => setWinner(e.target.value)}
            value={winner}
            placeholder="Winner"
            style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <div style={{ width: "30%" }}>
            <input
              type="text"
              onChange={(e) => setMethod(e.target.value)}
              value={method}
              placeholder="Method"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "30%" }}>
            <input
              type="text"
              onChange={(e) => setRound(e.target.value)}
              value={round}
              placeholder="Round"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "30%" }}>
            <input
              type="text"
              onChange={(e) => setTime(e.target.value)}
              value={time}
              placeholder="Time"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
        <input
              type="text"
              onChange={(e) => setImage(e.target.value)}
              value={image}
              placeholder="Image Url"
              style={{ width: "100%", padding: "6px", margin: "5px 0", boxSizing: "border-box" }}
            />
        </div>
        <button type="submit" style={{ width: "100%", padding: "8px", backgroundColor: "#d9534f", color: "#fff", border: "none", borderRadius: "4px", cursor: "pointer" }}>Add Result</button>
      </form>
      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
    </div>
  );
};

export default WorkoutForm;
