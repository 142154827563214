import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const DownloadFighterMusic = () => {
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdfs = async () => {
      try {
        const response = await axios.get('/fightermusic/pdfs');
        setPdfs(response.data);
      } catch (err) {
        setError('Error retrieving PDFs');
      } finally {
        setLoading(false);
      }
    };

    fetchPdfs();
  }, []);

  const downloadPdf = async (id, name) => {
    try {
      const response = await axios.get(`/fightermusic/download/${id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      alert('Error downloading PDF');
    }
  };

  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className='bg010'>
    <div className="text-white py-5">
      <div className="container mt-5 p-4 shadow rounded" style={{ maxWidth: "500px", backgroundColor: "white",opacity:"0.9" }}>
        <h1 className="text-center mb-4 text-dark">Fighter Music</h1>
        <ul className="list-group">
          {pdfs.map((pdf) => (
            <li key={pdf._id} className="list-group-item d-flex justify-content-between align-items-center">
              <span>{pdf.name}</span>
              <button 
                className="btn btn-danger btn-sm"
                onClick={() => downloadPdf(pdf._id, pdf.name)}
              >
                Download
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </div>
  );
};

export default DownloadFighterMusic;
