// npx json-server --watch data/db.json --port 5000
// delete buttons #ff4d4f

/////////////////////////////////
// const express = require("express");
// mport express from "express";

// const app = express();
// const port = 6000;
// const cors = require("cors");

// app.use(cors());
// app.use(express.json());

// const { MongoClient, ServerApiVersion, ObjectId } = require("mongodb");
// const uri =
//   "mongodb+srv://equipmentM:ChaamiV2001@cluster0.hnyjidx.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0";

// const client = new MongoClient(uri, {
//   serverApi: {
//     version: ServerApiVersion.v1,
//     strict: true,
//     deprecationErrors: true,
//   },
// });

// async function run() {
//   try {
//     // Connect the client to the server (optional starting in v4.7)
//     await client.connect();

//     // Create collections
//     const equipments = client.db("EquipmentInventory").collection("equipments");
//     const deletedEquipments = client
//       .db("EquipmentInventory")
//       .collection("deletedEquipments");
//     const maintance = client.db("EquipmentInventory").collection("maintance");

//     // Insert equipment to db
//     app.post("/upload_equipment", async (req, res) => {
//       const data = req.body;
//       const result = await equipments.insertOne(data);
//       res.send(result);
//     });

//     // Update equipment data
//     app.patch("/equip/:id", async (req, res) => {
//       const id = req.params.id;
//       const updateEquipmentData = req.body;
//       const filter = { _id: new ObjectId(id) };
//       const options = { upsert: true };

//       const updateDoc = {
//         $set: {
//           ...updateEquipmentData,
//         },
//       };

//       const result = await equipments.updateOne(filter, updateDoc, options);
//       res.send(result);
//     });

//     // Delete equipment from db
//     app.delete("/equip/:id", async (req, res) => {
//       const id = req.params.id;
//       const filter = { _id: new ObjectId(id) };

//       try {
//         // Get the equipment to be deleted
//         const equipmentToDelete = await equipments.findOne(filter);

//         // Add deletedDate field with current date
//         const date = new Date();
//         equipmentToDelete.deletedDate = date.toISOString().split("T")[0];

//         // Store the equipment in the deletedEquipments collection
//         await deletedEquipments.insertOne(equipmentToDelete);

//         // Delete the equipment from the equipments collection
//         const result = await equipments.deleteOne(filter);

//         res
//           .status(200)
//           .json({ message: "Equipment deleted successfully", result });
//       } catch (error) {
//         console.log("Error deleting equipment:", error);
//         res.status(500).json({ message: "Error deleting equipment", error });
//       }
//     });

//     // Find equipment by category
//     app.get("/all-equipments", async (req, res) => {
//       let query = {};
//       if (req.query?.category) {
//         query = { category: req.query.category };
//       }
//       const result = await equipments.find(query).toArray();
//       res.send(result);
//     });

//     // Find deleted equipments
//     app.get("/deleted-equipments", async (req, res) => {
//       const result = await deletedEquipments.find().toArray();
//       res.send(result);
//     });

//     // Get single equipment data
//     app.get("/equip/:id", async (req, res) => {
//       const id = req.params.id;
//       const filter = { _id: new ObjectId(id) };
//       const result = await equipments.findOne(filter);
//       res.send(result);
//     });

//     // Fetch maintenance data by ID
//     app.get("/maintenance/:id", async (req, res) => {
//       const id = req.params.id;

//       try {
//         const maintenanceData = await maintance.findOne({
//           _id: new ObjectId(id),
//         });
//         res.json(maintenanceData);
//       } catch (error) {
//         console.error("Error fetching maintenance data:", error);
//         res
//           .status(500)
//           .json({ message: "Error fetching maintenance data", error });
//       }
//     });

//     // Update maintenance data
//     app.patch("/maintenance/:id", async (req, res) => {
//       const id = req.params.id;
//       const updateMaintenanceData = req.body;
//       const filter = { _id: new ObjectId(id) };
//       const options = { upsert: true };

//       const updateDoc = {
//         $set: {
//           ...updateMaintenanceData,
//         },
//       };

//       const result = await maintance.updateOne(filter, updateDoc, options);
//       res.send(result);
//     });

//     // Store maintenance data
//     app.post("/maintenance", async (req, res) => {
//       const { equipmentName, purchaseDate, lastMaintain, mTime } = req.body;

//       try {
//         const maintenanceData = {
//           equipmentName,
//           purchaseDate,
//           lastMaintain,
//           mTime,
//         };

//         const result = await maintance.insertOne(maintenanceData);

//         res
//           .status(200)
//           .json({ message: "Maintenance data stored successfully", result });
//       } catch (error) {
//         console.log("Error storing maintenance data:", error);
//         res
//           .status(500)
//           .json({ message: "Error storing maintenance data", error });
//       }
//     });

//     // Send a ping to confirm a successful connection
//     await client.db("admin").command({ ping: 1 });
//     console.log(
//       "Pinged your deployment. You successfully connected to MongoDB!"
//     );
//   } finally {
//     // Ensures that the client will close when you finish/error
//     // await client.close();
//   }
// }

// run().catch(console.dir);

// app.listen(port, () => {
//   console.log(`Example app listening on port ${port}`);
// });
///////////////////////////////

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import workoutContextProvider
import { WorkoutsContextProvider } from "./context/WorkoutContext";
import { AuthContextProvider } from "./context/AuthContext";

import store from "./redux/store";

import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <WorkoutsContextProvider>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </WorkoutsContextProvider>
  </AuthContextProvider>
);
