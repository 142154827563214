import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import 'bootstrap/dist/css/bootstrap.min.css';

const UpdateWorkoutForm = ({ workout, onUpdate, setIsEditing }) => {
  const [title, setTitle] = useState(workout.title);
  const [fighter1, setFighter1] = useState(workout.fighter1);
  const [fighter2, setFighter2] = useState(workout.fighter2);
  const [winner, setWinner] = useState(workout.winner);
  const [weightclass, setWeightclass] = useState(workout.weightclass);
  const [method, setMethod] = useState(workout.method);
  const [round, setRound] = useState(workout.round);
  const [time, setTime] = useState(workout.time);
  const [image, setImage] = useState(workout.image);

  const { user } = useAuthContext();
  const { dispatch } = useWorkoutsContext();

  const handleSubmit = async (e) => {
    console.log("handle update pressed");
    e.preventDefault();
    if (!user) {
      return;
    }
    try {
      const response = await fetch("/api/workouts/" + workout._id, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          title,
          fighter1,
          fighter2,
          winner,
          weightclass,
          method,
          round,
          time,
          image
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update workout");
      }

      const json = await response.json();
      onUpdate(json);
      console.log("json", json, "json");
      dispatch({ type: "UPDATE_WORKOUT", payload: json });
      alert("RESULT UPDATED");
    } catch (error) {
      console.error("Error updating workout:", error.message);
      alert("Failed to update workout");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container" style={{padding:"10px", minHeight:"410px"}}>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="title" className="form-label">Title:</label>
          <input
            type="text"
            className="form-control"
            id="title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="fighter1" className="form-label">Fighter 1:</label>
          <input
            type="text"
            className="form-control"
            id="fighter1"
            onChange={(e) => setFighter1(e.target.value)}
            value={fighter1}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="fighter2" className="form-label">Fighter 2:</label>
          <input
            type="text"
            className="form-control"
            id="fighter2"
            onChange={(e) => setFighter2(e.target.value)}
            value={fighter2}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="weightclass" className="form-label">Weight Class:</label>
          <input
            type="text"
            className="form-control"
            id="weightclass"
            onChange={(e) => setWeightclass(e.target.value)}
            value={weightclass}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="winner" className="form-label">Winner:</label>
          <input
            type="text"
            className="form-control"
            id="winner"
            onChange={(e) => setWinner(e.target.value)}
            value={winner}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="image" className="form-label">Image URL:</label>
          <input
            type="text"
            className="form-control"
            id="image"
            onChange={(e) => setImage(e.target.value)}
            value={image}
          />
        </div>
        </div>
        <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="method" className="form-label">Method:</label>
          <input
            type="text"
            className="form-control"
            id="method"
            onChange={(e) => setMethod(e.target.value)}
            value={method}
          />
        </div>
    
     
        <div className="col-md-4">
          <label htmlFor="round" className="form-label">Round:</label>
          <input
            type="text"
            className="form-control"
            id="round"
            onChange={(e) => setRound(e.target.value)}
            value={round}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="time" className="form-label">Time:</label>
          <input
            type="text"
            className="form-control"
            id="time"
            onChange={(e) => setTime(e.target.value)}
            value={time}
          />
        </div>
        </div>
     
    
      <div className="d-flex justify-content-center">
        <span className="material-symbols-outlined" onClick={handleSubmit}>
          done
        </span>
        <span
          className="material-symbols-outlined"
          onClick={() => {
            setIsEditing(false);
          }}
        >
          cancel
        </span>
      </div>
    </form>
  );
};

export default UpdateWorkoutForm;
