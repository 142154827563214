import React from "react";
import AnnNavBar from "../components/AnnNavBar";
import AnnList from "../components/AnnList";
import useFetch from "../hooks/useFetch";

function AnnHome() {
  const {
    data: blogs,
    isLoading,
    error,
  } = useFetch("http://localhost:5000/blogs");

  const handleDelete = (id) => {
    const newBlogs = blogs.filter((blog) => {
      return blog.id !== id;
    });

    // setData(newBlogs); // Assuming you were using setData to update state
  };

  // Reverse the order of blogs array
  const reversedBlogs = blogs ? [...blogs].reverse() : [];

  return (
    // <div className="bg30">
    <div>
      {/* <AnnNavBar /> */}
      <div className="annhome"
      style={{overflow: "auto", scrollbarWidth: "none", msOverflowStyle: "none" , marginTop:"opx"}}>
        <div className="row">
          <div className="col">
            {error && <div className="alert alert-danger">{error}</div>}
            {isLoading && <div className="alert alert-info">Loading...</div>}
            {reversedBlogs && (
              <AnnList
                blogs={reversedBlogs}
                // title="All Blogs"
                handleDelete={handleDelete}
              />
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default AnnHome;
