// npx
// json-server --watch data/db.json --port 5000

import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Router,
} from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import FlipFusionHome from "./pages/FlipFusionHome";
// import PHome from "./pages/PHome";
import Home from "./pages/Home";
import AboutUs from "./pages/Aboutus";;
import TicTacToe from "./pages/TicTacToe";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";

import AnnHome from "./pages/AnnHome";
import AnnCreate from "./pages/AnnCreate";
import { Switch } from "@mui/material";
import AnnDetails from "./components/AnnDetails";
import NotFound from "./pages/NotFound";
import AddEmp from "./pages/AddEmp";
import AllEmployees from "./pages/AllEmployees";
import EmpEdit from "./pages/EmpEdit";
import AllUsers from "./pages/AllUsers";
import SendEmail from "./pages/sendEmail";
import Footer from "./components/Footer";
import HomePage from "./pages/HomaPage";
import RelaxingGame from "./pages/RelaxingGame";
import AddWorkoutsForTrainer from "./pages/AddWorkoutsForTrainer";
import GameCenter from "./pages/GameCenter";
import { FileUpload, Reviews } from "@mui/icons-material";
import Displaytrainer from "./pages/DisplayTrainer";
import UploadForm from "./pages/FileUpload";
import DownloadLink from "./pages/Downloadlink";
import FighterMusicUpload from "./pages/FighterMusicUpload";
import DownloadFighterMusic from "./pages/DownloadFighterMusic";
import RegisterFighter from "./pages/RegisterFighter";
import AboutUsParagraph from "./pages/AboutUsParagraph";
import AllFighters from "./pages/AllFighters";
import NavigationBar from "./components/NavigationBar";
import Message from "./pages/Message";
import ViewAllMessages from "./pages/ViewAllMessages";
import Gallery from "./pages/Gallery";
import RegisterChoice from "./pages/RegisterChoice";
import RegisterNewFighter from "./pages/RegisterNewFighter";
import Applications from "./pages/Applications";
import song from "./assets/song.mp3";

function App() {
  const { user } = useAuthContext();

    useEffect(() => {
    // Wait for the user to interact with the page before playing the audio
    const onUserInteraction = () => {
      const audio = new Audio(song);
      audio.play().catch((err) => {
        console.error("Audio playback failed:", err);
      });
      document.removeEventListener("click", onUserInteraction);
    };

    // Listen for a click anywhere on the document to trigger audio play
    document.addEventListener("click", onUserInteraction);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("click", onUserInteraction);
    };
  }, []);

  return (

    <div className="App" style={{overflow: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }}>
      <BrowserRouter>
        <Navbar />
       

        <div className="pages">
        
          <Routes>
            <Route
              exact
              path="/"
              element= {<HomePage />} 
            />
           
            <Route
              exact
              path="/manageWorkouts"
              element={  <Home />}
            />
            <Route
              exact
              path="/aboutUs"
              element={ <AboutUs /> }
            />
            <Route
              exact
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/signup"
              element={!user ? <Signup /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/tictactoe"
              element={user ? <TicTacToe /> : <Navigate to="/" />}
             
            />
           
            <Route
              exact
              path="/flipfusion"
              element={user ? <FlipFusionHome /> : <Navigate to="/" />}
             
            />
            <Route
              exact
              path="/ann"
              element={<AnnHome /> }
            
            />
            <Route
              exact
              path="/annCreate"
              element={user ? <AnnCreate /> : <Navigate to="/" />}
             
            />
            <Route
              exact
              path="/annDetails/:id"
              element={<AnnDetails />}
              
            />
          
            <Route
              path="/add"
              element={user ? <AddEmp /> : <Navigate to="/" />}
              // element={<Signup />}
            />
             <Route
              path="/gallery"
              element={  <Gallery />}
              // element={<Signup />}
            />
              <Route
              path="/registerchoice"
              element={  <RegisterChoice />}
              // element={<Signup />}
            />
             <Route
              path="/registernewfighter"
              element={  <RegisterNewFighter />}
              // element={<Signup />}
            />
              <Route
              path="/applications"
              element={  <SendEmail />}
              // element={<Signup />}
            />
            <Route
              path="/viewEmployees"
              element={user ? <AllEmployees /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/editemp"
              element={user ? <EmpEdit /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            {/* <Route
              path="/viewleaves"
              element={user ? <ViewLeaves /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/addleave"
              element={user ? <AddLeave /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            <Route
              path="/viewUsers"
              element={user ? <AllUsers /> : <Navigate to="/" />}
              // element={<Signup />}
            />

<Route
              path="/viewmessages"
              element={user ? <ViewAllMessages /> : <Navigate to="/" />}
              // element={<Signup />}
            />

<Route
              path="/message"
              element={  <Message />}
              // element={<Signup />}
            />
            {/* <Route
              path="/editleave"
              element={user ? <EditLeave /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/attendence"
              element={user ? <AttendanceForm /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/viewAttendence"
              element={user ? <AttendanceByDate /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            {/* <Route
              path="/viewsalary"
              element={user ? <ViewSalary /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/addsalary"
              element={user ? <AddSal /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/editsalary"
              element={user ? <SalEdit /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            <Route
              path="/sendemail"
              element={user ? <SendEmail /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            {/* <Route
              path="/checkhartbeat"
              element={user ? <HeartRateCalculator /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/HeartRateCalculator"
              element={user ? <HeartRateCalculator2 /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            {/* <Route
              path="/homepage"
              element={user ? <PHome /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            <Route
              path="/relaxinggame"
              element={user ? <RelaxingGame /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            {/* <Route
              path="/admindashboard"
              element={user ? <AdminDashboard /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/trainerdashboard"
              element={user ? <TrainerDashBoard /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/monthlysalaray"
              element={user ? <MonthlySalary /> : <Navigate to="/" />}
              // element={<Signup />}
            /> */}
            <Route
              path="/addworkoutsfortrainer"
              element={user ? <AddWorkoutsForTrainer /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/gamecenter"
              element={user ? <GameCenter /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            {/* <Route
              path="/supplementhome"
              element={user ? <HomeScreenSupplement /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/cart"
              element={user ? <Cartscreen /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/orders"
              element={user ? <Ordersscreen /> : <Navigate to="/" />} */}
              {/* // element={<Signup />}
            /> */}
            {/* <Route
              path="/adminsup"
              element={user ? <Adminscreensup /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/classadmin"
              element={user ? <AdminScreen /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/admin/updateclass/:id"
              element={user ? <UpdateClass /> : <Navigate to="/" />}
              // element={<Signup />}
            />
            <Route
              path="/admin/editsupplement/:supplementid"
              element={user ? <Editsupplement /> : <Navigate to="/" />}
            /> */}
            {/* <Route
              path="/classes"
              element={user ? <ClassesScreen /> : <Navigate to="/" />}
            />
            <Route
              path="/packages"
              element={user ? <PackageScreen /> : <Navigate to="/" />}
            />
            <Route
              path="/purchasing/:packageItemid/:fromdate/:todate"
              element={user ? <PurchasingScreen /> : <Navigate to="/" />}
            />
            <Route
              path="/admin/updatepackage/:id"
              element={user ? <UpdatePackage /> : <Navigate to="/" />}
            /> */}
            {/* <Route
              path="/eq/addeq"
              element={user ? <AddEquipment /> : <Navigate to="/" />}
            />
            <Route
              path="/userDisplay"
              element={user ? <UserDisplay /> : <Navigate to="/" />}
            />

            <Route
              path="/manageEq"
              element={user ? <ManageEquip /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/dashboard/edit-equipments/:id"
              element={user ? <EditEquip /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/admin/dashboard/edit-equipments/:id"
              element={user ? <EditEquip /> : <Navigate to="/" />}
              loader={({ params }) =>
                fetch(`http://localhost:8000/equip/${params.id}`).then(
                  (response) => response.json()
                )
              }
            /> */}

            {/* <Route
              path="/admin/dashboard/deleted-equipments"
              element={user ? <DeletedEquipments /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/dashboard/track-maintance"
              element={user ? <TrackMaintenance /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/dashboard/track-maintance/calender"
              element={user ? <MaintenanceCalendar /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/dashboard/track-maintance/count"
              element={user ? <EquipmentCount /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/equipment/:id"
              element={user ? <SingleEquipment /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/feedBack"
              element={user ? <Mainfeedback /> : <Navigate to="/" />}
            />

            <Route
              path="/contactUs"
              element={user ? <ContactUs /> : <Navigate to="/" />}
            />

            <Route
              path="/complains"
              element={user ? <Complaints /> : <Navigate to="/" />}
            />

            <Route
              path="/ratings"
              element={user ? <Ratings /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/ratingform"
              element={user ? <RatingForm /> : <Navigate to="/" />}
            />

            <Route
              path="/complains/trainer"
              element={user ? <Trainercomplainform /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/complains/sup"
              element={user ? <Supplemencomplaints /> : <Navigate to="/" />}
            />

            <Route
              path="/complains/other"
              element={user ? <Othercomplaints /> : <Navigate to="/" />}
            />

            <Route
              path="/disfeedback"
              element={user ? <DisplayContactUs /> : <Navigate to="/" />}
            />

            <Route
              path="/displaytrainer"
              element={user ? <Displaytrainer /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/displaysupplementscomp"
              element={user ? <Displaysuplement /> : <Navigate to="/" />}
            />

            <Route
              path="/displayothercomp"
              element={user ? <Displayother /> : <Navigate to="/" />}
            />

            <Route
              path="/admin/dashboard/edit-maintenance/:id"
              element={user ? <EditMaintaice /> : <Navigate to="/" />}
            />

            <Route
              path="/AContactUs"
              element={user ? <ADisplayContactUs /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/Arating"
              element={user ? <ARating /> : <Navigate to="/" />}
            />

            <Route
              path="/Acomplain"
              element={user ? <ComplaintsBoxes /> : <Navigate to="/" />}
            />

            <Route
              path="/Aform1"
              element={user ? <Acomplaintstrainer /> : <Navigate to="/" />}
            /> */}

            {/* <Route
              path="/Aform2"
              element={user ? <AcomplaintsSupplement /> : <Navigate to="/" />}
            />

            <Route
              path="/Aform3"
              element={user ? <Acomplaintsother /> : <Navigate to="/" />}
            />

            <Route
              path="/apply-fitness-consultant"
              element={<ApplyFitnessConsultant />}
            />

            <Route
              path="/fcm/fitness-consultants"
              element={user ? <FitnessConsultants /> : <Navigate to="/" />}
            /> */}
{/* 
<Route
              path="/fcm/fitness-consultants/list"
              element={user ? <AllFitnessConsultants /> : <Navigate to="/" />}
            />

            <Route path="/admin-manage-feedback" element={<AContactUs />} /> */}

            <Route
              path="/uploadfile"
              element={user ? <UploadForm /> : <Navigate to="/" />}
            />

          <Route
              path="/downloadfile"
              element={user ? <DownloadLink /> : <Navigate to="/" />}
            />
               <Route
              path="/fightermusicupload"
              element={user ? <FighterMusicUpload /> : <Navigate to="/" />}
            />
               <Route
              path="/downloadfightermusic"
              element={user ? <DownloadFighterMusic /> : <Navigate to="/" />}
            />
              <Route
              path="/registerfighter"
              element={ <RegisterFighter />}
            />

          <Route
              path="/aboutUsParagraph"
              element={ <AboutUsParagraph /> }
            />

            
          <Route
              path="/allfighters"
              element={user ? <AllFighters /> : <Navigate to="/" />}
            />

            <Route
              path="/hfcnavigationbar"
              element={<Navbar />}
            />

             <Route
              path="/hfcfooter"
              element={<Footer /> }
            />    

            <Route
              path="*"
              element={user ? <NotFound /> : <Navigate to="/" />}
              // element={<Signup />}
            />
          </Routes>
        </div>
        
        <Footer />
      </BrowserRouter>
    </div>
  
  );
}

export default App;
