import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './Gallery.css'; // Uncomment this line if you have a CSS file for styling

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [hashtags, setHashtags] = useState('');
    const [searchTag, setSearchTag] = useState('');

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async (tag = '') => {
        try {
            const response = await axios.get('http://localhost:4000/api/gallery', {
                params: { hashtag: tag }
            });
            setImages(response.data);
        } catch (error) {
            console.error('Error fetching images', error);
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const files = e.target.elements.images.files;

        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }
        formData.append('hashtags', hashtags);

        try {
            await axios.post('http://localhost:4000/api/gallery/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            fetchImages();
        } catch (error) {
            console.error('Error uploading images', error);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchImages(searchTag);
    };

    return (
        <div className="App">
            <h1>Image Gallery</h1>
            <form onSubmit={handleUpload}>
                <input type="file" name="images" multiple />
                <input
                    type="text"
                    placeholder="Hashtags (comma separated)"
                    value={hashtags}
                    onChange={(e) => setHashtags(e.target.value)}
                />
                <button type="submit">Upload</button>
            </form>

            <form onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="Search by hashtag"
                    value={searchTag}
                    onChange={(e) => setSearchTag(e.target.value)}
                />
                <button type="submit">Search</button>
            </form>

            <div className="gallery">
                {images.map(image => (
                    <div key={image._id} className="image-item">
                        <img src={`http://localhost:4000${image.url}`} alt="" />
                        <div>{image.hashtags.join(', ')}</div>
                        <a href={`http://localhost:4000${image.url}`} download>Download</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
