import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img015.jpg";

export default function RegisterNewFighter() {
  const [FirstName, setFirstName] = useState("");
  const [Email, setEmail] = useState("");
  const [Age, setAge] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [Club, setClub] = useState("");
  const [Discipline, setDisclipline] = useState("");
  const [Coach, setCoach] = useState("");
  const [YOE, setYOE] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [err, setErr] = useState("");

  const sendData = (e) => {
    e.preventDefault();

    const newFighterData = {
      FirstName,
      Email,
      Age,
      Height,
      Weight,
      Club,
      Discipline,
      Coach,
      YOE,
    };

    axios
      .post("https://www.hfc.lk/newfighter/add", newFighterData)
      .then(() => {
        const confirmed = window.confirm(
          "Please ensure all information is accurate before submitting."
        );
        if (confirmed) {
          alert(
            "Registration successful! \n\nyou will be notified when fight opportunities become available."
          );
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status) {
          if (err.response.status === 325) {
            alert("Fighter already exists.");
          } else if (err.response.status === 324) {
            alert("Duplicate NIC. Please enter a unique NIC.");
          } else {
            alert("An error occurred while adding the employee.");
          }
        } else {
          alert("An error occurred while adding the employee.");
        }
      });
  };

  const resetForm = () => {
    setFirstName("");
    setEmail("");
    setAge("");
    setHeight("");
    setWeight("");
    setClub("");
    setDisclipline("");
    setCoach("");
    setYOE("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "10px", // added padding for mobile view
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row", // changed to column for better stacking in mobile view
          width: "85%",
          backgroundColor: "white",
          borderRadius: "10px",
          maxWidth: "700px",
          overflow: "hidden", // ensure no content overflows
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <style>
          {`
            @media (max-width: 768px) {
              .image-div {
                display: none;
              }
              .form-container {
                flex: 2; // take full width in mobile view
              }
            }
            .custom-placeholder::placeholder {
              color: white;
            }
          `}
        </style>
        <div
          className="image-div"
          style={{
            flex: 1.2,
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        ></div>
        <div
          className="form-container"
          style={{
            flex: 1,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgb(33, 37, 41)",
            color: "white",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            overflow: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "white",
              paddingTop: "10px",
            }}
          >
            <h3>Fighter Registration</h3>
            <h5>Highland Fighting Championship</h5>
          </div>
          <form
            onSubmit={sendData}
            className="container"
            style={{ padding: "7px" }}
          >
            <div className="row">
              <div className="col-md-12 mb-2">
                <input
                  type="text"
                  placeholder="Name"
                  value={FirstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <input
                  type="email"
                  placeholder="Email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Age"
                  value={Age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Height"
                  value={Height}
                  onChange={(e) => setHeight(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <input
                  type="text"
                  placeholder="Weight"
                  value={Weight}
                  onChange={(e) => setWeight(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Club"
                  value={Club}
                  onChange={(e) => setClub(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <input
                  type="text"
                  placeholder="Contact No"
                  value={Discipline}
                  onChange={(e) => setDisclipline(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <input
                  type="text"
                  placeholder="Coach"
                  value={Coach}
                  onChange={(e) => setCoach(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <input
                  type="text"
                  placeholder="Years Of Experience"
                  value={YOE}
                  onChange={(e) => setYOE(e.target.value)}
                  required
                  className="form-control custom-placeholder"
                  style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <button type="submit" className="btn btn-danger btn-block">
                  Submit
                </button>
              </div>
              <div className="col-md-6 mb-2">
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-outline-danger btn-block"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
