import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.link}>
        Go to Home Page
      </Link>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
    animation: "fadeIn 1s ease-out", // Fade in animation
  },
  title: {
    fontSize: "2em",
    marginBottom: "20px",
    color: "#333",
    fontFamily: "Arial, sans-serif",
    animation: "bounceIn 1s ease-out", // Bounce in animation
  },
  text: {
    fontSize: "1.2em",
    marginBottom: "20px",
    color: "#555",
    fontFamily: "Arial, sans-serif",
    animation: "fadeInUp 1s ease-out", // Fade in and slide up animation
  },
  link: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background-color 0.3s ease",
    animation: "fadeIn 1s ease-out", // Fade in animation
  },
};

export default NotFound;
