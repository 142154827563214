import React, { useEffect } from "react";
import logo from "../assets/HFC logo-2-White.png"; // replace with the actual path to your logo

const splashScreenStyles = {
  position: "fixed",
  width: "100%",
  height: "100%",
  backgroundColor: "rgb(33, 37, 41)", // Change to your desired background color
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
  animation: "fadeOut 1s ease-in 5s forwards", // Fade out animation after 2 seconds
};

const spinningLogoStyles = {
  width: "180px", // Adjust size as needed
  animation: "spinY 2s linear infinite, heartbeat 1.5s ease-in-out infinite", // Smooth spin and heartbeat animations
};

const keyframes = `
  @keyframes spinY {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
  }
  @keyframes heartbeat {
    0%, 100% { transform: scale(1); }
    25%, 75% { transform: scale(1.1); }
    50% { transform: scale(1.05); }
  }
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const SplashScreen = ({ onFinish }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish();
    }, 3000); // Show splash screen for 3 seconds
    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <>
      <style>{keyframes}</style>
      <div style={splashScreenStyles}>
        <img src={logo} alt="Company Logo" style={spinningLogoStyles} />
      </div>
    </>
  );
};

export default SplashScreen
