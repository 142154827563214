import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import img from '../assets/img016.jpg';

export default function AddEmp() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Role, setRole] = useState("");
  const [Email, setEmail] = useState("");

  const sendData = (e) => {
    e.preventDefault();

    const newEmployee = {
      FirstName,
      LastName,
      Role,
      Email,
    };

    axios
      .post("http://localhost:4000/employee/add", newEmployee)
      .then(() => {
        alert("Access Provided");
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 325) {
          alert("Employee already exists.");
        } else if (err.response.status === 324) {
          alert("Duplicate NIC. Please enter a unique NIC.");
        } else {
          alert("An error occurred while adding the employee.");
        }
      });
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setRole("");
    setEmail("");
  };

  return (
    <div className="background-image" style={{
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <div className="container bg-light p-3 rounded" style={{maxWidth:"400px", opacity:"0.9"}}>
        <center>
        <h2 className="form-heading mb-4">Provide Access</h2>
        </center>
        <form onSubmit={sendData}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              value={LastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <select
              className="form-control"
              value={Role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="" disabled>Select Role</option>
              <option value="Fighter">Fighter</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="button-group">
            <button type="submit" className="btn btn-danger me-2">
              Submit
            </button>
          
            <Link to="/viewEmployees" className="btn btn-outline-danger">
              All Members
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
