import React from 'react'
import TicTacToeBoard from '../components/TicTacToeBoard'

function TicTacToe() {
  return (
    <div className='bg11'>
        <TicTacToeBoard/>
    </div>
  )
}

export default TicTacToe