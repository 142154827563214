import React from 'react'
import TicTacToeInnerBoard from './TicTacToeInnerBoard'
import TicTacToeGameOver from './TicTacToeGameOver'
import { useState, useEffect } from 'react'
import TicTacToeGameState from './TicTacToeGameState'
import TicTacToeReset from './TicTacToeReset'

import GameOverSound from '../sounds/gameOver.wav'
import ClickSound from '../sounds/click.wav'
import NewGameSound from '../sounds/newGame.wav'

const gameOverSound = new Audio(GameOverSound)
gameOverSound.volume = 1

const clickSound = new Audio(ClickSound)
clickSound.volume = 1

const newGameSound = new Audio(NewGameSound)
newGameSound.volume = 1


const PLAYER_X = "X"
const PLAYER_O = "O"

const winningCombinations = [
  // rows
  {combo:[0,1,2], strikeClass: "strike-row-1"},
  {combo:[3,4,5], strikeClass: "strike-row-2"},
  {combo:[6,7,8], strikeClass: "strike-row-3"},

  // columns
  {combo:[0,3,6], strikeClass: "strike-column-1"},
  {combo:[1,4,7], strikeClass: "strike-column-2"},
  {combo:[2,5,8], strikeClass: "strike-column-3"},

  // diagonals
  {combo:[0,4,8], strikeClass: "strike-diagonal-1"},
  {combo:[2,4,6], strikeClass: "strike-diagonal-2"},
  

];



function checkWinner(tiles, setStrikeClass, setGameState) {
  for(const  {combo, strikeClass} of winningCombinations) {
    const tileValue1 = tiles[combo[0]]
    const tileValue2 = tiles[combo[1]]
    const tileValue3 = tiles[combo[2]]

    if(tileValue1 != null && tileValue1 == tileValue2 && tileValue1 == tileValue3){
      setStrikeClass(strikeClass)

      if(tileValue1 === PLAYER_X) {
        setGameState(TicTacToeGameState.playerXWins)
      }
      else{
        setGameState(TicTacToeGameState.playerOWins)
      }
      return
    }
  }

  const areAllTilesFilledIn = tiles.every((tile) => tile !== null)
  if(areAllTilesFilledIn) {
    setGameState(TicTacToeGameState.draw)
      
  }
}

 

function TicTacToeBoard() {

 

    const [tiles, setTiles] = useState(Array(9).fill(null))
    const [playerTurn, setPlayerTurn] = useState(PLAYER_X)
    const [strikeClass, setStrikeClass] = useState(null);
    const [gameState, setGameState] = useState(TicTacToeGameState.inProgress)


    const handleReset = () => {
      // console.log("reset button clicked")
      newGameSound.play()
      setGameState(TicTacToeGameState.inProgress)
      setTiles(Array(9).fill(null))
      setPlayerTurn(PLAYER_X)
      setStrikeClass(null)
    } 
    const handleTileClick = (index) => {
      if(gameState !== TicTacToeGameState.inProgress) {
        return
      }

      clickSound.play()
    

      if(tiles[index] !== null) {
        return
      }
      // console.log(index)
      const newTiles = [...tiles]
      newTiles[index] = playerTurn
      setTiles(newTiles)
      if(playerTurn === PLAYER_X) {
        setPlayerTurn(PLAYER_O)
      }
      else {
        setPlayerTurn(PLAYER_X)
      }
    }

   useEffect(()=> {
    checkWinner(tiles, setStrikeClass, setGameState)
   }, [tiles]) 

  //  useEffect(()=>{
  //   if(tiles.some((tile) => tile !== null)) {
  //     clickSound.play()
  //   }
  //  },[tiles])

   useEffect(()=>{
    if(gameState !== TicTacToeGameState.inProgress) {
      gameOverSound.play()
    }
   },[gameState])

  return (
    <div className='tictactoe-board'> 

        <h1>Tic Tac Toe </h1>

        <TicTacToeInnerBoard 

          playerTurn={playerTurn} 
          tiles={tiles} 
          onTileClick={handleTileClick}
          strikeClass={strikeClass}
        />
        <TicTacToeGameOver gameState={gameState}/>
        <TicTacToeReset gameState={gameState} onReset={handleReset}/>
    </div>
  )
}

export default TicTacToeBoard