import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

const LandingPageVideo = () => {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("https://www.youtube.com/embed/WmHCCT3Orzc?autoplay=1&controls=1&controlsList=nodownload&vq=hd720");

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleVideoChange = (url) => {
    setVideoUrl(url);
  };

  return (
    <div>
      <div onClick={handleShowModal} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
        PLAY
        <PlayCircleOutlinedIcon fontSize='medium' style={{ marginLeft: "7px", height: "20px" }} />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" style={{ borderRadius: '10px' }}>
              <div className="modal-header" style={{ background: "black", color: "white", borderBottom: 'none' }}>
                <h5 className="modal-title">YouTube Video</h5>
                <button type="button" className="close" onClick={handleCloseModal} aria-label="Close" style={{ color: 'white' }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="embed-responsive embed-responsive-16by9">
                <iframe
  className="embed-responsive-item"
  src={videoUrl}
  allowFullScreen
  title="YouTube video"
  allow="autoplay; encrypted-media"
></iframe>

                </div>
              </div>

              <div className="modal-footer bg-black border-top-0 p-3">
  <button
    type="button"
    className="btn btn-outline-danger btn-sm mx-2 btn-animated"
    onClick={() => handleVideoChange("https://www.youtube.com/embed/WmHCCT3Orzc?autoplay=1&controls=1&controlsList=nodownload&vq=hd720")}
  >
    HFC-01 After Movie
  </button>
  <button
    type="button"
    className="btn btn-outline-danger btn-sm mx-2 btn-animated"
    onClick={() => handleVideoChange("https://www.youtube.com/embed/_ItxerUkTzo?autoplay=1&controls=1&controlsList=nodownload&vq=hd720")}
  >
    HFC-01 Live Stream
  </button>
  <button
    type="button"
    className="btn btn-outline-danger btn-sm mx-2 btn-animated"
    onClick={() => handleVideoChange("https://www.youtube.com/embed/ftXbKTNeKuw?autoplay=1&controls=1&controlsList=nodownload&vq=hd720")}
  >
    HFC-02 After Movie
  </button>
  <button
    type="button"
    className="btn btn-outline-danger btn-sm mx-2 btn-animated"
    onClick={() => handleVideoChange("https://www.youtube.com/embed/VtOLX0zmQ60?autoplay=1&controls=1&controlsList=nodownload&vq=hd720")}
  >
    HFC-02 Live Stream
  </button>
</div>



            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPageVideo;
