import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import LandingPageVideo from "../components/LandingPageVideo";
import SplashScreen from "../components/SplashScreen";
import NavigationBar from "../components/NavigationBar";

import imgcard01 from "../assets/cardimg01.jpg";
import imgcard02 from "../assets/cardimg02.jpg";
import imgcard03 from "../assets/cardimg03.jpg";

const SocialLinks = () => (
  <div
    className="text-right"
    style={{ paddingTop: "8px", paddingRight: "6%", paddingBottom: "10px" }}
  >
    <a href="https://www.tiktok.com/@highlandfighting?_t=8pxY9ATlzeB&_r=1%0A" className="text-light me-3">
      <i className="bi bi-tiktok"></i>
    </a>
    <a href="https://www.instagram.com/highlandchampionship?igsh=MWZiNHIxZzd2ejBwMg%3D%3D%0A" className="text-light me-3">
      <i className="bi bi-instagram"></i>
    </a>
    <a href="https://www.facebook.com/highlandfightingchampionship/" className="text-light me-3">
      <i className="bi bi-facebook"></i>
    </a>
    <a href="https://www.youtube.com/@HighlandFightingChampionship" className="text-light">
      <i className="bi bi-youtube"></i>
    </a>
  </div>
);

const HeroSection = () => (
  <div className="text-white text-center">
    <h1 className="display-5 mb-2">WELCOME TO</h1>
    <h1 className="display-4 mb-4">HIGHLAND FIGHTING CHAMPIONSHIP</h1>
    <h3 className="display-6 mb-4">"WHERE HISTORY MEETS MODERNITY"</h3>
    <div style={{ padding: "0px" }}>
      <Link
        to="https://www.facebook.com/highlandfightingchampionship/photos"
        className="btn btn-outline-danger btn-lg mr-2"
        style={{ marginTop: "10px" }}
      >
        GALLERY
      </Link>
      <Link
        className="btn btn-danger btn-lg mr-2"
        style={{ marginTop: "10px" }}
      >
        <LandingPageVideo />
      </Link>
    </div>
  </div>
);

const Card = ({ imgSrc, title, link }) => (
  <div className="col-md-6 col-lg-4 d-flex">
    <div
      className="card mb-3 bg-dark text-white d-flex flex-column"
      style={{
        transition: "transform 0.2s, box-shadow 0.2s",
        cursor: "pointer",
        flex: 1,
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
        e.currentTarget.style.boxShadow = "0 10px 20px rgba(0, 0, 0, 0.3)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      }}
    >
      <div className="position-relative">
        <img
          src={imgSrc}
          className="card-img-top"
          alt="image"
          style={{ height: "170px", width: "100%", objectFit: "cover" }}
        />
        <div className="position-absolute bottom-0 start-0 w-100 p-3">
          <Link to={link} style={{ textDecoration: "none", color: "white" }}>
            <h5 className="card-title text-center">{title}</h5>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

const CardList = () => (
  <div className="row justify-content-center pl-4 pr-4 ">
    <Card
      imgSrc = {imgcard01}
      title="REGISTRATION"
      link="/registerchoice"
    />
    <Card
      imgSrc = {imgcard02}
      title="PASSED FIGHTS"
      link="/manageWorkouts"
    />
    <Card
      imgSrc = {imgcard03}
      title="CONTACT US"
      link="/message"
    />
  </div>
);

const HomePage = () => {
  const { user } = useAuthContext();
  const [showSplash, setShowSplash] = useState(true);

  const handleSplashFinish = () => {
    setShowSplash(false);
  };

  return (
    <>
      {showSplash && <SplashScreen onFinish={handleSplashFinish} />}

      <div
        className="bg001"
        style={{
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          paddingBottom: "50px",
        }}
      >
        {/* <NavigationBar/> */}
        <SocialLinks />

        <div className="container-fluid p-0">
          <HeroSection />
          <div
            style={{
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              marginTop: "40px",
              maxWidth: "900px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <CardList />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
