import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img033.jpg";

export default function Message() {
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sendData = (e) => {
    e.preventDefault();

    const newEmployee = {
      name,
      contactNumber,
      message,
      category,
    };

    axios
      .post("https://www.hfc.lk/message/add", newEmployee)
      .then(() => {
        alert("Message Sent");
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 325) {
          alert("Employee already exists.");
        } else if (err.response.status === 324) {
          alert("Duplicate NIC. Please enter a unique NIC.");
        } else {
          alert("An error occurred while adding the employee.");
        }
      });
  };

  const resetForm = () => {
    setName("");
    setContactNumber("");
    setMessage("");
    setCategory("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <style>
        {`
          .custom-placeholder::placeholder {
            color: white;
          }
        `}
      </style>
      <div
        style={{
          display: "flex",
          width: "85%",
          borderRadius: "10px",
          maxWidth: "700px",
          maxHeight: "500px",
             ...(isMobile && {
            marginBottom: "150px",
            marginTop: "50px",
          }),
        }}
      >
        {!isMobile && (
          <div
            style={{
              flex: 1,
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          ></div>
        )}
        <div
          style={{
            flex: 1,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgb(33, 37, 41)",
            color: "white",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            ...(isMobile && {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }),
          }}
        >
          <center style={{ color: "white" }}>
            <h2>Contact Us</h2>
            <h3>Highland Fighting Championship</h3>
            <h4>Send Message</h4>
          </center>
          <form onSubmit={sendData}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                required
              />
            </div>
            <div className="mb-3">
              <select
                className="form-control custom-placeholder"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                required
              >
                <option value="" disabled>
                  Who Am I
                </option>
                <option value="Sponsor">Sponsor</option>
                <option value="Journalist">Journalist</option>
                <option value="Fighter">Fighter</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ backgroundColor: "rgb(33, 37, 41)", color: "white" }}
                required
              />
            </div>
            <div className="button-group">
              <button type="submit" className="btn btn-danger me-2">
                Send
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
