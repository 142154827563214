import React from "react";
import { Link } from "react-router-dom";

function AnnNavBar() {
  return (
    <div>
      <nav className="annnavbar">
        <div className="navlinks">
          {/* <a href="/">Home</a> */}
          {/* <a href="/annCreate">Create Announcement</a> */}
          {/* <Link to="/ann">Home</Link>
          <Link to="/annCreate">Create Announcement</Link> */}
        </div>
      </nav>
    </div>
  );
}

export default AnnNavBar;
