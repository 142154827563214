import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import img from "../assets/img033.jpg";

export default function AllFighters() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getEmployees = async () => {
      const endpoint = showDeletedRecords
        ? "https://www.hfc.lk/fighter/displayAllEmpIncludingDeleted"
        : "https://www.hfc.lk/fighter/displayAllEmp";
      try {
        const { data } = await axios.get(endpoint);
        setEmployees(data);
        setFilteredEmployees(data.slice(0, pageSize));
      } catch (err) {
        alert(err.message);
      }
    };
    getEmployees();
  }, [showDeletedRecords, pageSize]);

  useEffect(() => {
    const filtered = employees.filter(({ FirstName, LastName, NIC, Coach, City, School, Club, Email }) => {
      const term = searchTerm.toLowerCase();
      return [FirstName, LastName, NIC, Coach, City, School, Club, Email].some(field =>
        field.toLowerCase().includes(term)
      );
    });

    const startIndex = (currentPage - 1) * pageSize;
    setFilteredEmployees(filtered.slice(startIndex, startIndex + pageSize));
  }, [employees, searchTerm, pageSize, currentPage]);

  const handleEditClick = (id) => navigate(`/editemp`, { state: id });

  const downloadFile = async (url, filename, errorMsg) => {
    try {
      const { data } = await axios.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch {
      alert(errorMsg);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const generatePdf = () => {
    const doc = new jsPDF('landscape');
    doc.setProperties({
      title: "Highland Fighting Championship Fighters",
      subject: "Highland Fighting Championship Fighters",
      keywords: "fighter, list",
    });

    const headers = [
      "FirstName", "LastName", "NIC", "NickName", "Email", "Age",
      "Height", "Weight", "Club", "Discipline", "Coach", "School",
      "City", "YOE",
    ];

    const data = filteredEmployees.map(({ FirstName, LastName, NIC, NickName, Email, Age, Height, Weight, Club, Discipline, Coach, School, City, YOE }) => [
      FirstName, LastName, NIC, NickName, Email, Age, Height, Weight, Club, Discipline, Coach, School, City, YOE,
    ]);

    doc.setFontSize(18);
    doc.text("Highland Fighting Championship - Fighter Details", 148, 20, { align: "center" });
    doc.autoTable({ head: [headers], body: data, startY: 30 });
    return doc;
  };

  const downloadPdf = () => {
    const doc = generatePdf();
    window.open(URL.createObjectURL(doc.output("blob")), "_blank");
  };

  const handleDeleteClick = async (eid) => {
    if (window.confirm("Are you sure you want to delete this employee?")) {
      try {
        await axios.delete(`https://www.hfc.lk/fighter/delete/${eid}`);
        const { data } = await axios.get("https://www.hfc.lk/fighter/displayAllEmp");
        setEmployees(data);
      } catch (error) {
        console.error("Error deleting employee:", error.message);
      }
    }
  };

  const totalPages = Math.ceil(employees.length / pageSize);

  return (
    <div
      className="vh-100 d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="container rounded-3 p-2 bg-light "  style={{
          opacity: "0.87",
          marginLeft: "10px",
          marginRight: "10px",
          width: "90%",
        }}>
        <center style={{ color: 'black', padding: "20px" }}>
          <h3>Applications</h3>
          <h4>Highland Fighting Championship</h4>
        </center>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <input
            type="text"
            placeholder="Search Fighter"
            className="form-control me-2"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={downloadPdf} className="btn btn-danger">
            <i className="bi bi-download"></i>
          </button>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table-striped table-hover">
            <thead className="thead-dark" style={{ whiteSpace: "nowrap" }}>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>NIC</th>
                <th>NickName</th>
                <th>Email</th>
                <th>Age</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Club</th>
                <th>Discipline</th>
                <th>Coach</th>
                <th>School</th>
                <th>City</th>
                <th>YOE</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee._id}>
                  <td>{employee.FirstName}</td>
                  <td>{employee.LastName}</td>
                  <td>{employee.NIC}</td>
                  <td>{employee.NickName}</td>
                  <td>{employee.Email}</td>
                  <td>{employee.Age}</td>
                  <td>{employee.Height}</td>
                  <td>{employee.Weight}</td>
                  <td>{employee.Club}</td>
                  <td>{employee.Discipline}</td>
                  <td>{employee.Coach}</td>
                  <td>{employee.School}</td>
                  <td>{employee.City}</td>
                  <td>{employee.YOE}</td>
                  <td style={{ minWidth: "175px" }}>
                    <button className="btn btn-outline-danger btn-sm me-2" onClick={() => downloadFile(`/fighter/download/${employee.pdf}`, `${employee.FirstName}.pdf`, "Error downloading PDF")}>
                      <i className="bi bi-file-earmark-arrow-down"></i>
                    </button>
                    <button className="btn btn-outline-danger btn-sm me-2" onClick={() => downloadFile(`/fighter/download/music/${employee.music}`, `${employee.LastName}.mp3`, "Error downloading MP3")}>
                      <i className="bi bi-music-note-beamed"></i>
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm me-2"
                      onClick={() => handleEditClick(employee)}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(employee._id)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn btn-danger"
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
