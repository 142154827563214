import React from 'react'
import TicTacToeGameState from './TicTacToeGameState'


function TicTacToeReset({gameState, onReset}) {

  if(gameState==TicTacToeGameState.inProgress){
    return
  }  
  return (
    <button onClick={onReset} className='tictactoe-reset'>TicTacToeReset</button>
  )
}

export default TicTacToeReset