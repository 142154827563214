import { useState } from 'react';
import { useSignup } from '../hooks/useSignup';
import img from '../assets/img005.jpg';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signup, error, isLoading } = useSignup();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await signup(email, password);
    };

    return (
        <div style={{
            paddingTop: 30,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '90vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            overflow: 'auto',
            backgroundImage: `url(${img})`
        }}>
            <div className='forms'>
                <form className='signup' onSubmit={handleSubmit} style={{ opacity: '0.9' }}>
                    <h3>Sign up</h3>

                    <label>Email:</label>
                    <input
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <label>Password:</label>
                    <input
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />

                    <button disabled={isLoading} className='btn btn-danger'>Sign up</button>
                    {error && <div className='error'>{error}</div>}
                </form>
            </div>
        </div>
    );
};

export default Signup;
