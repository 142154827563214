export const getAllSupplementsReducer = (
  state = { supplements: [] },
  action
) => {
  switch (action.type) {
    case "GET_SUPPLEMENTS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_SUPPLEMENTS_SUCCESS":
      return {
        loading: false,
        supplements: action.payload,
      };
    case "GET_SUPPLEMENTS_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const addSupplementsReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_SUPPLEMENTS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_SUPPLEMENTS_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_SUPPLEMENTS_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSupplementByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SUPPLEMENTBYID_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_SUPPLEMENTBYID_SUCCESS":
      return {
        loading: false,
        supplement: action.payload,
      };
    case "GET_SUPPLEMENTBYID_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const editSupplementReducer = (state = {}, action) => {
  switch (action.type) {
    case "EDIT_SUPPLEMENTBYID_REQUEST":
      return {
        editloading: true,
        ...state,
      };
    case "EDIT_SUPPLEMENTBYID_SUCCESS":
      return {
        editloading: false,
        editsuccess: true,
      };
    case "EDIT_SUPPLEMENTBYID_FAILED":
      return {
        editerror: action.payload,
        editloading: false,
      };
    default:
      return state;
  }
};
