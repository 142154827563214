import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../assets/img026.jpg";

export default function UpdateEmp() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    NIC: "",
    NickName: "",
    Email: "",
    Age: "",
    Height: "",
    Weight: "",
    Club: "",
    Discipline: "",
    Coach: "",
    School: "",
    City: "",
    YOE: "",
    file: null,
    file2: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setFormData({
        FirstName: location.state.FirstName || "",
        LastName: location.state.LastName || "",
        NIC: location.state.NIC || "",
        NickName: location.state.NickName || "",
        Email: location.state.Email || "",
        Age: location.state.Age || "",
        Height: location.state.Height || "",
        Weight: location.state.Weight || "",
        Club: location.state.Club || "",
        Discipline: location.state.Discipline || "",
        Coach: location.state.Coach || "",
        School: location.state.School || "",
        City: location.state.City || "",
        YOE: location.state.YOE || "",
        file: location.state.file || null,
        file2: location.state.file2 || null,
      });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
  };

  const sendData = async (e) => {
    e.preventDefault();

    if (!window.confirm("Confirm update? Changes can't be undone")) return;

    if (!location.state || !location.state._id) {
      console.error("Invalid employee data for update");
      return;
    }

    try {
      await axios.put(
        `https://www.hfc.lk/fighter/Update/${location.state._id}`,
        formData
      );
      alert("UPDATED SUCCESSFULLY")
      navigate("/allfighters");
    } catch (error) {
      console.error("Error updating fighter details:", error);
    }
  };

  return (
    // <div
    //   className="d-flex justify-content-center align-items-center vh-100"
    //   style={{
    //     backgroundImage: `url(${img})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     overflowX: "auto", // Enable horizontal scrolling
    //   }}
    // >
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: "100%",
          maxHeight: "90%",
          aspectRatio: "16/9", // Maintain a 16:9 aspect ratio
        }}
      >
        <div
          className="d-flex bg-white rounded"
          style={{
            width: "100%",
            maxWidth: "900px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            className="d-none d-md-block bg-cover"
            style={{
              backgroundImage: `url(${img})`,
              flex: "1 1 0",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="p-4 d-flex flex-column justify-content-center bg-dark text-white"
            style={{
              flex: "1 1 0",
              overflowY: "auto",
            }}
          >
            <div className="text-center m-1">
              <h3>Update Registration</h3>
              <h4>Highland Fighting Championship</h4>
              <h5>Season 03</h5>
            </div>
            <form onSubmit={sendData} className="container">
              <div className="row mb-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="FirstName"
                    value={formData.FirstName}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="LastName"
                    value={formData.LastName}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="NIC"
                    name="NIC"
                    value={formData.NIC}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Nick Name"
                    name="NickName"
                    value={formData.NickName}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <input
                    type="email"
                    placeholder="Email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Age"
                    name="Age"
                    value={formData.Age}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Height"
                    name="Height"
                    value={formData.Height}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Weight"
                    name="Weight"
                    value={formData.Weight}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Club"
                    name="Club"
                    value={formData.Club}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Discipline"
                    name="Discipline"
                    value={formData.Discipline}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Coach"
                    name="Coach"
                    value={formData.Coach}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="School"
                    name="School"
                    value={formData.School}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="City"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Years Of Experience"
                    name="YOE"
                    value={formData.YOE}
                    onChange={handleChange}
                    required
                    className="form-control bg-dark text-white"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <button type="submit" className="btn btn-danger btn-block">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
