import React from 'react'

import TicTacToeGameState from './TicTacToeGameState'

function TicTacToeGameOver({gameState}) {

    switch (gameState) {
        case TicTacToeGameState.inProgress:
            return <div className='tictactoe-game-over'> In Progress </div>
        case TicTacToeGameState.playerOWins:
            return <div className='tictactoe-game-over'> Player O Wins </div>
        case TicTacToeGameState.playerXWins:
            return <div className='tictactoe-game-over'> Player X Wins </div>
        case TicTacToeGameState.draw:
            return <div className='tictactoe-game-over'> Draw </div>

        default:
            break;
    }

  
  
}

export default TicTacToeGameOver