import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import img from '../assets/img019.jpg';

export default function AllEmployees() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);

  useEffect(() => {
    function getEmployees() {
      const endpoint = showDeletedRecords
        ? "http://localhost:4000/employee/displayAllEmpIncludingDeleted"
        : "http://localhost:4000/employee/displayAllEmp";

      axios
        .get(endpoint)
        .then((res) => {
          setEmployees(res.data);
          setFilteredEmployees(res.data.slice(0, pageSize));
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    getEmployees();
  }, [showDeletedRecords, pageSize]);

  useEffect(() => {
    const filtered = employees.filter((employee) => {
      const nameMatch =
        employee.FirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.LastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.NIC.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.Coach.toLowerCase().includes(searchTerm.toLowerCase()) ||

        employee.City.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.School.toLowerCase().includes(searchTerm.toLowerCase()) ||

        employee.Email.toLowerCase().includes(searchTerm.toLowerCase()) 
        // employee.LastName.toLowerCase().includes(searchTerm.toLowerCase()) ||


      return nameMatch;
    });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setFilteredEmployees(filtered.slice(startIndex, endIndex));
  }, [employees, searchTerm, pageSize, currentPage]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    doc.setProperties({
      title: "Employee List",
      subject: "List of All Employees",
      keywords: "employee, list",
    });

    const headers = [
      "Employee ID",
      "First Name",
      "Last Name",
      "Role",
      "Email",
    ];

    const data = filteredEmployees.map((employee) => [
      employee._id,
      employee.FirstName,
      employee.LastName,
      employee.Role,
      employee.Email,
    ]);

    doc.setFontSize(18);
    doc.text("Employee Details", 105, 20, { align: "center" });
    doc.setFontSize(12);

    const columnStyles = {
      0: { cellWidth: 25, halign: "left" },
      1: { cellWidth: 25, halign: "left" },
      2: { cellWidth: 25, halign: "left" },
      3: { cellWidth: 25, halign: "left" },
      4: { cellWidth: 20, halign: "left" },
    };

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      columnStyles,
    });

    return doc;
  };

  const downloadPdf = () => {
    const doc = generatePdf();
    const url = URL.createObjectURL(doc.output("blob"));
    window.open(url, "_blank");
  };

  const handleDeleteClick = async (eid) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this employee?"
    );

    if (isConfirmed) {
      try {
        await axios.delete(`http://localhost:4000/employee/delete/${eid}`);
        axios
          .get("http://localhost:4000/employee/displayAllEmp")
          .then((res) => {
            setEmployees(res.data);
            setFilteredEmployees(res.data);
          })
          .catch((err) => {
            alert(err.message);
          });
      } catch (error) {
        console.error("Error deleting employee:", error.message);
      }
    }
  };

  const totalPages = Math.ceil(employees.length / pageSize);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div
      className="background-image vh-100 d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container rounded-3 p-5" style={{ maxWidth: "700px", opacity:"0.9" }}>
        <h1 className="text-white text-center mb-4">All Members</h1>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <input
            type="text"
            placeholder="Search by Role, ID"
            className="form-control me-2"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={downloadPdf} className="btn btn-danger">
            <i className="bi bi-download"></i>
          </button>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table-striped table-hover">
            <thead className="thead">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee._id}>
                  <td>{employee.FirstName}</td>
                  <td>{employee.LastName}</td>
                  <td>{employee.Role}</td>
                  <td>{employee.Email}</td>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm me-2"
                      onClick={() => navigate("/editemp", { state: employee })}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteClick(employee._id)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-danger"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-white">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn btn-danger "
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
