//importing necessary functions and modules from the 'redux' library
import { combineReducers } from "redux";

import { createStore, applyMiddleware } from "redux";

//importing the 'redux-thunk' middleware for handling asynchronous actions
import { thunk } from "redux-thunk";

//importing 'composeWithDevTools' from 'redux-devtools-extension' for development tools support
//import { composeWithDevTools } from "redux-devtools-extension";

import {
  getAllSupplementsReducer,
  addSupplementsReducer,
  getSupplementByIdReducer,
  editSupplementReducer,
} from "../reducers/supplementReducers";

import { cartReducer } from "../reducers/cartReducer";

import {
  placeOrderReducer,
  getUserOrdersReducer,
  getAllOrdersReducer
} from "../reducers/supOrderReducer";

//combining reducers into a single reducer
const finalReducer = combineReducers({
  getAllSupplementsReducer: getAllSupplementsReducer,
  cartReducer: cartReducer,
  placeOrderReducer: placeOrderReducer,
  getUserOrdersReducer: getUserOrdersReducer,
  addSupplementsReducer: addSupplementsReducer,
  getSupplementByIdReducer: getSupplementByIdReducer,
  editSupplementReducer: editSupplementReducer,
  getAllOrdersReducer: getAllOrdersReducer
});

const cartItems = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

//initial state for the Redux store
const initialState = {
  cartReducer: {
    cartItems: cartItems,
  },
};

//enhancing the store with development tools support using 'composeWithDevTools'
//const composeEnhancers = composeWithDevTools({});

//creating the Redux store with the combined reducer, initial state, and middleware (thunk)
const store = createStore(
  finalReducer,
  initialState,
  applyMiddleware(thunk)
  // composeEnhancers(applyMiddleware(thunk))
);

//exporting the configured Redux store
export default store;